import React, { useEffect, useState } from "react";
import { CircularProgress, Divider, Modal } from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import MDProgress from "components/MDProgress";
import LinearProgressWithLabel from "components/reusable/LinearProgressWithLabel";

const initialProgress = {
  sentEmails: 0,
  totalEmails: 0,
};

const ModalConfirmSendAll = ({
  open = false,
  onClose,
  title,
  message,
  onConfirm,
  isLoading,
  totalEmailsCount = 0,
}) => {
  const [sendingProgress, setSendingProgress] = useState(initialProgress);

  const [sendingStarted, setSendingStarted] = useState(false);

  console.log(
    "Sending",
    sendingProgress.totalEmails === 0
      ? 0
      : (sendingProgress.sentEmails * 100) / sendingProgress.totalEmails
  );

  useEffect(() => {
    setSendingProgress({ ...sendingProgress, totalEmails: totalEmailsCount });
  }, [totalEmailsCount]);

  useEffect(async () => {
    if (sendingStarted) {
      const connection = new HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_EMAIL_PROGRESS_HUB_URL)
        .configureLogging(LogLevel.Information)
        .build();
      connection.on("ReceiveProgress", (progress) => {
        console.log("Progress", progress);
        setSendingProgress(progress);
      });
      await connection.start();
      return () => {
        connection.stop();
        setSendingStarted(false);
      };
    }

    return null;
  }, [sendingStarted]);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <MDBox
        width={600}
        py={2}
        px={2}
        style={{ background: "white", outline: "none", borderRadius: 8 }}
      >
        <MDTypography>{title}</MDTypography>
        <Divider />
        <MDTypography fontSize="medium">{message}</MDTypography>

        {sendingStarted && (
          <>
            <Divider />
            <LinearProgressWithLabel
              value={
                sendingProgress.totalEmails === 0
                  ? 0
                  : (sendingProgress.sentEmails * 100) / sendingProgress.totalEmails
              }
              text={`${sendingProgress.sentEmails}/${sendingProgress.totalEmails}`}
            />
          </>
        )}

        <Divider />
        <MDBox display="flex" justifyContent="center" alignItems="center" mt={3}>
          <MDBox mr={1}>
            <MDButton color="secondary" onClick={onClose} disabled={isLoading}>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox ml={1}>
            <MDButton
              color="info"
              onClick={() => {
                onConfirm();
                setSendingStarted(true);
              }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress color="white" size={20} /> : "Confirmer"}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Modal>
  );
};

export default ModalConfirmSendAll;
