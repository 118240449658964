import * as types from "../types";

const INITIAL_STATE = {
  toggleModalEditStudent: false,
  toggleModalConfirmDelete: false,
  entityToDeleteId: null,

  toggleModalChooseReceiver: false,
  reportCardId: null,

  toggleModalEditParent: false,
  toggleModalEditTeacher: false,
  toggleModalConfirmSendAll: false,

  toggleModalSendingNoticesProgress: false,

  toggleModalNoticeDetails: false,

  toggleModalDeleteClassStudents: false,
  toggleModalTransferStudents: false,
  modalSourceClassId: -1,

  modalClassId: -1,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.TOGGLE_MODAL_EDIT_STUDENT:
      return {
        ...state,
        toggleModalEditStudent: action.payload.toggle,
        studentId: action.payload.id,
      };

    case types.TOGGLE_MODAL_EDIT_PARENT:
      return {
        ...state,
        toggleModalEditParent: action.payload.toggle,
        parentId: action.payload.id,
      };

    case types.TOGGLE_MODAL_EDIT_TEACHER:
      return {
        ...state,
        toggleModalEditTeacher: action.payload.toggle,
        teacherId: action.payload.id,
      };

    case types.TOGGLE_MODAL_CONFIRM_DELETE:
      return {
        ...state,
        toggleModalConfirmDelete: action.payload.toggle,
        entityToDeleteId: action.payload.id,
      };

    case types.TOGGLE_MODAL_CHOOSE_RECEIVER:
      return {
        ...state,
        toggleModalChooseReceiver: action.payload.toggle,
        reportCardId: action.payload.id,
      };

    case types.TOGGLE_MODAL_CONFIRM_SEND_ALL:
      return {
        ...state,
        toggleModalConfirmSendAll: action.payload.toggle,
      };

    case types.TOGGLE_MODAL_SENDING_NOTICES_PROGRESS:
      return {
        ...state,
        toggleModalSendingNoticesProgress: action.payload.toggle,
      };

    case types.TOGGLE_MODAL_NOTICE_DETAILS:
      return {
        ...state,
        toggleModalNoticeDetails: action.payload.toggle,
        noticeId: action.payload.id,
      };

    case types.TOGGLE_MODAL_DELETE_CLASS_STUDENTS:
      return {
        ...state,
        toggleModalDeleteClassStudents: action.payload.toggle,
        modalClassId: action.payload.modalClassId,
      };

    case types.TOGGLE_MODAL_TRANSFER_STUDENTS:
      return {
        ...state,
        toggleModalTransferStudents: action.payload.toggle,
        modalSourceClassId: action.payload.modalSourceClassId,
      };

    default:
      return state;
  }
};
