/* eslint-disable react/prop-types */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

// Images
// import logoXD from "assets/images/small-logos/logo-xd.svg";
// import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
// import logoSlack from "assets/images/small-logos/logo-slack.svg";
// import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
// import logoJira from "assets/images/small-logos/logo-jira.svg";
// import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";
import Icon from "@mui/material/Icon";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as action from "../../../store/actions";
import * as types from "../../../store/types";

export default function allStudentsData() {
  const { students } = useSelector((state) => state.students);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action.getAllStudents());
    console.log("Load");
  }, []);

  const Student = ({ image, name, email, enabled = true }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image} name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="inline" variant="button" fontWeight="medium">
          {name}
          {!enabled && (
            <MDTypography display="inline" variant="button" fontWeight="light" fontStyle="italic">
              {" "}
              [Désactivé]
            </MDTypography>
          )}
        </MDTypography>
        {/* <MDTypography variant="caption">{email}</MDTypography> */}
      </MDBox>
    </MDBox>
  );

  const ClassName = ({ name, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {name}
      </MDTypography>
      {/* <MDTypography variant="caption">{description}</MDTypography> */}
    </MDBox>
  );

  const handleOpenModalEditStudent = (id) => {
    console.log("Id", id);
    dispatch({ type: types.TOGGLE_MODAL_EDIT_STUDENT, payload: { toggle: true, id } });
  };

  const handleOpenModalConfirmDeleteStudent = (id) => {
    console.log("Id", id);
    dispatch({ type: types.TOGGLE_MODAL_CONFIRM_DELETE, payload: { toggle: true, id } });
  };

  const getStudentsRows = () => {
    let rows = [];
    students.map((student) => {
      rows.push({
        student: (
          <Student name={`${student.firstName} ${student.lastName}`} enabled={student.enabled} />
        ),
        class: <ClassName name={student.schoolClass.className} />,
        action: student.enabled && (
          <MDBox>
            <MDButton iconOnly onClick={() => handleOpenModalEditStudent(student.id)}>
              <Icon fontSize="small" color="info">
                edit
              </Icon>
            </MDButton>
            <MDButton iconOnly onClick={() => handleOpenModalConfirmDeleteStudent(student.id)}>
              <Icon fontSize="small" color="error">
                delete
              </Icon>
            </MDButton>
          </MDBox>
        ),
      });
    });

    return rows;
  };

  return {
    columns: [
      { Header: "Nom complet", accessor: "student", width: "45%", align: "left" },
      { Header: "Classe", accessor: "class", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: getStudentsRows(),
  };
}
