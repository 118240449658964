import { smsMaxLength } from "../constants/sms";

export const hasNull = (obj) => {
  Object.values(obj).every((value) => {
    if (value === null) {
      return true;
    }

    return false;
  });
};

export const hasValue = (obj) => {
  const result =
    obj &&
    Object.values(obj).some((value) => {
      if (value !== null && value !== undefined && value !== "") {
        return true;
      }
      return false;
    });

  return result;
};

export const exceededSmsLength = (htmlContent, withAttachments = false) => {
  const div = document.createElement("div");
  div.innerHTML = htmlContent;
  const strWithoutHtmlTags = div.textContent || div.innerText || "";

  if (withAttachments) {
    return strWithoutHtmlTags.length > smsMaxLength.withAttachments;
  }

  return strWithoutHtmlTags.length > smsMaxLength.withoutAttachments;
};
