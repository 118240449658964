import { navRoutes } from "constants/routes";
import React, { useState, useContext, createContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import * as action from "../store/actions";

const fakeAuthProvider = {
  isAuthenticated: false,
  signin(callback) {
    fakeAuthProvider.isAuthenticated = true;
    setTimeout(callback, 1000); // fake async
  },
  signout(callback) {
    fakeAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};

const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const localStorageValue = JSON.parse(localStorage.getItem("loggedIn"));

  const [isAuthed, setIsAuthed] = React.useState(localStorageValue);

  let from = location.state?.from?.pathname || "/";

  const signin = async (credentials) => {
    dispatch(
      action.login(credentials, () => {
        setIsAuthed(true);
        navigate(from, { replace: true });
      })
    );

    // fakeAuthProvider.signin(() => {
    //   setUser(newUser);
    //   callback();
    // })
  };

  const signout = () => {
    dispatch(
      action.logout(() => {
        setIsAuthed(false);
        navigate(navRoutes.LOGIN, { replace: true });
      })
    );
  };

  const value = { isAuthed, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
