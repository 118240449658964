import React from "react";
import { CircularProgress, Divider, Modal } from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useDispatch } from "react-redux";
import * as types from "../../../store/types";

const ModalConfirmDelete = ({ open = false, onClose, title, message, onConfirm, isLoading }) => {
  const dispatch = useDispatch();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <MDBox
        width={600}
        py={2}
        px={2}
        style={{ background: "white", outline: "none", borderRadius: 8 }}
      >
        <MDTypography>{title}</MDTypography>
        <Divider />
        <MDTypography fontSize="medium">{message}</MDTypography>
        <Divider />
        <MDBox display="flex" justifyContent="center" alignItems="center" mt={3}>
          <MDBox mr={1}>
            <MDButton
              color="secondary"
              onClick={() =>
                dispatch({
                  type: types.TOGGLE_MODAL_CONFIRM_DELETE,
                  payload: { toggle: false },
                })
              }
              disabled={isLoading}
            >
              Annuler
            </MDButton>
          </MDBox>
          <MDBox ml={1}>
            <MDButton color="error" onClick={onConfirm} disabled={isLoading}>
              {isLoading ? <CircularProgress color="white" size={20} /> : "Supprimer"}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Modal>
  );
};

export default ModalConfirmDelete;
