import * as types from "../types";

const INITIAL_STATE = {
  schoolYears: [],
  isLoadingSchoolYears: false,
  schoolYearsLoaded: false,

  isAddingSchoolYear: false,
  schoolYearAdded: false,

  isDeletingSchoolYear: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_ALL_SCHOOL_YEARS_REQUEST:
      return {
        ...state,
        isLoadingSchoolYears: true,
      };

    case types.GET_ALL_SCHOOL_YEARS_SUCCESS:
      return {
        ...state,
        schoolYears: action.payload,
        isLoadingSchoolYears: false,
        schoolYearsLoaded: true,
      };

    case types.GET_ALL_SCHOOL_YEARS_FAILURE:
      return {
        ...state,
        isLoadingSchoolYears: false,
      };

    case types.ADD_SCHOOL_YEAR_REQUEST:
      return {
        ...state,
        isAddingSchoolYear: true,
      };

    case types.ADD_SCHOOL_YEAR_SUCCESS:
      return {
        ...state,
        isAddingSchoolYear: false,
        schoolYearAdded: true,
        schoolYears: [...state.schoolYears, action.payload],
      };

    case types.ADD_SCHOOL_YEAR_FAILURE:
      return {
        ...state,
        isAddingSchoolYear: false,
      };

    case types.DELETE_SCHOOL_YEAR_REQUEST:
      return {
        ...state,
        isDeletingSchoolYear: true,
      };

    case types.DELETE_SCHOOL_YEAR_SUCCESS:
      return {
        ...state,
        isDeletingSchoolYear: false,
      };

    case types.DELETE_SCHOOL_YEAR_FAILURE:
      return {
        ...state,
        isDeletingSchoolYear: false,
      };

    default:
      return state;
  }
};
