import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Card,
  CircularProgress,
  Grid,
  Icon,
  Modal,
  TextField,
  Typography,
  typographyClasses,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import ErrorMessage from "components/reusable/ErrorMessage";
import MDButton from "components/MDButton";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { newParentSchema } from "layouts/new-parent/validation-schema/newParentSchema";

import * as action from "../../../store/actions";
import * as types from "../../../store/types";

const ModalEditParent = ({ open = false, onClose }) => {
  const [dropDownStudentsOptions, setDropDownStudentsOptions] = useState([]);
  const [dropDownStudentBlurred, setDropDownStudentBlurred] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    students: [],
  });

  const { parent, parentLoaded, isUpdatingParent, parentExists } = useSelector(
    (state) => state.parents
  );

  const { students, studentsLoaded } = useSelector((state) => state.students);

  const { parentId } = useSelector((state) => state.modals);

  const dispatch = useDispatch();

  const initSelectedStudents = (allStudents) => {
    let selectedStudentsArray = [];
    parent.students.map((student) => {
      const foundStudent = allStudents.find((item) => item.id === student.id);
      selectedStudentsArray.push(foundStudent);
    });
    setSelectedStudents(selectedStudentsArray);
  };

  useEffect(() => {
    dispatch(action.getParent(parentId));
    dispatch(action.getAllStudents());
  }, [parentId]);

  useEffect(
    () => () => {
      dispatch({ type: types.RESET_PARENT_LOADED });
      dispatch(action.resetParentExistsFlag());
    },
    []
  );

  useEffect(() => {
    if (parentLoaded && studentsLoaded) {
      let allStudents = [];
      students.map((student) =>
        allStudents.push({
          id: student.id,
          label: `${student.firstName} ${student.lastName} - ${student.schoolClass.className}`,
        })
      );

      initSelectedStudents(allStudents);

      setInitialValues({
        firstName: parent.firstName,
        lastName: parent.lastName,
        email: parent.email || "",
        phoneNumber: parent.phoneNumber,
      });
    }
  }, [parentLoaded, studentsLoaded, parentId]);

  useEffect(() => {
    if (studentsLoaded) {
      let options = [];
      students.map((student) => {
        options.push({
          id: student.id,
          label: `${student.firstName} ${student.lastName} - ${student.schoolClass.className}`,
        });
      });
      setDropDownStudentsOptions(options);
    }
  }, [studentsLoaded, parentId]);

  const handleEditParent = (data) => {
    console.log("handleEditParent", { ...data, id: parent.id, students: selectedStudents });
    dispatch(action.updateParent({ ...data, id: parent.id, students: selectedStudents }));
  };

  const handleClose = () => {
    dispatch({ type: types.TOGGLE_MODAL_EDIT_PARENT, payload: { toggle: false } });
  };

  const handleSelectStudentChange = (newValue) => {
    const uniqueStudents = Array.from(new Set(newValue.map((s) => s.id))).map((id) =>
      newValue.find((s) => s.id === id)
    );
    console.log("Unique", uniqueStudents);
    setSelectedStudents([...uniqueStudents]);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box width={600} style={{ background: "white", outline: "none", borderRadius: 8 }}>
        <MDBox mt={3} mb={3}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2} display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="h5">Modification</MDTypography>
              <MDButton iconOnly onClick={handleClose}>
                <Icon fontSize="large">close</Icon>
              </MDButton>
            </MDBox>
            <Formik
              initialValues={initialValues}
              onSubmit={handleEditParent}
              validationSchema={newParentSchema}
              enableReinitialize
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <MDBox pt={2} px={2}>
                    <MDBox>
                      <MDInput
                        label="Nom"
                        name="lastName"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.lastName}
                        error={
                          props.touched.lastName &&
                          props.errors.lastName &&
                          props.errors.lastName !== ""
                        }
                        fullWidth
                      />
                      <ErrorMessage
                        message={
                          props.touched.lastName && props.errors.lastName && props.errors.lastName
                        }
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox pt={2} px={2}>
                    <MDBox>
                      <MDInput
                        label="Prénom"
                        name="firstName"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.firstName}
                        error={
                          props.touched.firstName &&
                          props.errors.firstName &&
                          props.errors.firstName !== ""
                        }
                        fullWidth
                      />
                      <ErrorMessage
                        message={
                          props.touched.firstName &&
                          props.errors.firstName &&
                          props.errors.firstName
                        }
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox pt={2} px={2}>
                    <MDBox>
                      <MDInput
                        label="Email"
                        name="email"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.email}
                        error={
                          props.touched.email && props.errors.email && props.errors.email !== ""
                        }
                        fullWidth
                      />
                      <ErrorMessage
                        message={props.touched.email && props.errors.email && props.errors.email}
                      />
                      <ErrorMessage
                        message={parentExists && "Cette adresse Email est déjà utilisée."}
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox pt={2} px={2}>
                    <MDBox>
                      <MDInput
                        label="Téléphone"
                        name="phoneNumber"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.phoneNumber}
                        error={
                          props.touched.phoneNumber &&
                          props.errors.phoneNumber &&
                          props.errors.phoneNumber !== ""
                        }
                        fullWidth
                      />
                      <ErrorMessage
                        message={
                          props.touched.phoneNumber &&
                          props.errors.phoneNumber &&
                          props.errors.phoneNumber
                        }
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox pt={2} px={2}>
                    <MDBox>
                      <Autocomplete
                        disablePortal
                        multiple
                        id="combo-box"
                        options={dropDownStudentsOptions}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Enfants"
                            error={selectedStudents.length === 0 && dropDownStudentBlurred}
                          />
                        )}
                        value={selectedStudents || []}
                        onChange={(event, newValue) => {
                          handleSelectStudentChange(newValue);
                        }}
                        onBlur={() => setDropDownStudentBlurred(true)}
                      />
                      <ErrorMessage
                        message={
                          selectedStudents.length === 0 &&
                          dropDownStudentBlurred &&
                          "Vous devez sélectionner au moins un élève."
                        }
                      />

                      {/* <MDInput
                        label="Classe"
                        name="className"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.className}
                        error={props.touched.className && props.errors.className !== ""}
                        fullWidth
                      />
                      <ErrorMessage message={props.touched.className && props.errors.className} /> */}
                    </MDBox>
                  </MDBox>
                  <MDBox
                    mt={4}
                    mb={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="blue"
                  >
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="info"
                      style={{
                        paddingLeft: "50px",
                        paddingRight: "50px",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                      }}
                      disabled={isUpdatingParent}
                    >
                      {isUpdatingParent ? <CircularProgress color="white" size={20} /> : "Modifier"}
                    </MDButton>
                  </MDBox>
                </form>
              )}
            </Formik>
          </Grid>
        </MDBox>
      </Box>
    </Modal>
  );
};
export default ModalEditParent;
