import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Card,
  CircularProgress,
  Grid,
  Icon,
  Modal,
  TextField,
  Typography,
  typographyClasses,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import ErrorMessage from "components/reusable/ErrorMessage";
import MDButton from "components/MDButton";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { newStudentSchema } from "layouts/new-student/validation-schema/newStudentSchema";

import * as action from "../../../store/actions";
import * as types from "../../../store/types";

const ModalEditStudent = ({ open = false, onClose }) => {
  const [dropDownClassesOptions, setDropDownClassesOptions] = useState([]);
  const [dropDownClassBlurred, setDropDownClassBlurred] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    schoolClassId: "",
  });

  const { isAddingStudent, student, studentLoaded, isUpdatingStudent } = useSelector(
    (state) => state.students
  );

  const { classes, classesLoaded } = useSelector((state) => state.schoolClasses);

  const { studentId } = useSelector((state) => state.modals);

  console.log("Studehnt", student);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action.getStudent(studentId));
    dispatch(action.getAllClasses());
  }, []);

  useEffect(() => {
    if (studentLoaded && classesLoaded) {
      const { id, className } = student.schoolClass;
      setSelectedClass({ id, label: className });

      setInitialValues({
        firstName: student.firstName,
        lastName: student.lastName,
        schoolClassId: student.schoolClass.id,
      });
    }
  }, [studentLoaded, classesLoaded]);

  useEffect(() => {
    if (classesLoaded) {
      let options = [];
      classes.map((schoolClass) => {
        options.push({ id: schoolClass.id, label: schoolClass.className });
      });
      setDropDownClassesOptions(options);
    }
  }, [classesLoaded]);

  const handleEditStudent = (data) => {
    console.log("handleEditStudent", { ...data, id: student.id, schoolClassId: selectedClass.id });
    dispatch(action.updateStudent({ ...data, id: student.id, schoolClassId: selectedClass.id }));
  };

  const handleClose = () => {
    dispatch({ type: types.TOGGLE_MODAL_EDIT_STUDENT, payload: { toggle: false } });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box width={600} style={{ background: "white", outline: "none", borderRadius: 8 }}>
        <MDBox mt={3} mb={3}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2} display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="h5">Modification</MDTypography>
              <MDButton iconOnly onClick={handleClose}>
                <Icon fontSize="large">close</Icon>
              </MDButton>
            </MDBox>
            <Formik
              initialValues={initialValues}
              onSubmit={handleEditStudent}
              validationSchema={newStudentSchema}
              enableReinitialize
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <MDBox pt={2} px={2}>
                    <MDBox>
                      <MDInput
                        label="Nom"
                        name="lastName"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.lastName}
                        error={
                          props.touched.lastName &&
                          props.errors.lastName &&
                          props.errors.lastName !== ""
                        }
                        fullWidth
                      />
                      <ErrorMessage
                        message={
                          props.touched.lastName && props.errors.lastName && props.errors.lastName
                        }
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox pt={2} px={2}>
                    <MDBox>
                      <MDInput
                        label="Prénom"
                        name="firstName"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.firstName}
                        error={
                          props.touched.firstName &&
                          props.errors.firstName &&
                          props.errors.firstName !== ""
                        }
                        fullWidth
                      />
                      <ErrorMessage
                        message={
                          props.touched.firstName &&
                          props.errors.firstName &&
                          props.errors.firstName
                        }
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox pt={2} px={2}>
                    <MDBox>
                      <Autocomplete
                        disablePortal
                        id="combo-box"
                        options={dropDownClassesOptions}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Classe"
                            error={selectedClass === null && dropDownClassBlurred}
                          />
                        )}
                        value={selectedClass || null}
                        onChange={(event, newValue) => {
                          console.log("NewVa", newValue);
                          setSelectedClass(newValue);
                        }}
                        onBlur={() => setDropDownClassBlurred(true)}
                      />
                      <ErrorMessage
                        message={
                          selectedClass === null &&
                          dropDownClassBlurred &&
                          "Vous devez sélectionner la classe de l'élève."
                        }
                      />

                      {/* <MDInput
                        label="Classe"
                        name="className"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.className}
                        error={props.touched.className && props.errors.className !== ""}
                        fullWidth
                      />
                      <ErrorMessage message={props.touched.className && props.errors.className} /> */}
                    </MDBox>
                  </MDBox>
                  <MDBox
                    mt={4}
                    mb={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="blue"
                  >
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="info"
                      style={{
                        paddingLeft: "50px",
                        paddingRight: "50px",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                      }}
                      disabled={isUpdatingStudent}
                    >
                      {isUpdatingStudent ? (
                        <CircularProgress color="white" size={20} />
                      ) : (
                        "Modifier"
                      )}
                    </MDButton>
                  </MDBox>
                </form>
              )}
            </Formik>
          </Grid>
        </MDBox>
      </Box>
    </Modal>
  );
};
export default ModalEditStudent;
