import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";

const TableEmptyState = ({ isEmpty = false, text }) =>
  isEmpty && (
    <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" pb={3}>
      <img alt="EmptyImg" src="/images/empty-state.png" height={180} width={230} />
      <MDTypography>{text}</MDTypography>
    </MDBox>
  );

export default TableEmptyState;
