import * as types from "../types";

const INITIAL_STATE = {
  parents: [],
  isLoadingParents: false,
  parentsLoaded: false,

  isAddingParent: false,
  parentAdded: false,

  isUpdatingParent: false,
  parentUpdated: false,

  isDeletingParent: false,
  parentDeleted: false,

  studentParents: [],
  isLoadingStudentParents: false,

  parent: null,
  isLoadingParent: false,
  parentLoaded: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_ALL_PARENTS_REQUEST:
      return {
        ...state,
        isLoadingParents: true,
      };

    case types.GET_ALL_PARENTS_SUCCESS:
      return {
        ...state,
        parents: action.payload,
        isLoadingParents: false,
        parentsLoaded: true,
      };

    case types.GET_ALL_PARENTS_FAILURE:
      return {
        ...state,
        isLoadingParents: false,
      };

    case types.ADD_PARENT_REQUEST:
      return {
        ...state,
        isAddingParent: true,
        parentExists: false,
      };

    case types.ADD_PARENT_SUCCESS:
      return {
        ...state,
        isAddingParent: false,
        parentAdded: true,
        parentExists: false,
        parents: [...state.parents, action.payload],
      };

    case types.ADD_PARENT_FAILURE:
      return {
        ...state,
        isAddingParent: false,
      };

    case types.ADD_OR_UPDATE_PARENT_FAILURE_CONFLICT:
      return {
        ...state,
        parentExists: true,
      };

    case types.UPDATE_PARENT_REQUEST:
      return {
        ...state,
        isUpdatingParent: true,
        parentExists: false,
      };

    case types.UPDATE_PARENT_SUCCESS:
      return {
        ...state,
        isUpdatingParent: false,
        parentUpdated: true,
        parentExists: false,
      };

    case types.UPDATE_PARENT_FAILURE:
      return {
        ...state,
        isUpdatingParent: false,
      };

    case types.DELETE_PARENT_REQUEST:
      return {
        ...state,
        isDeletingParent: true,
      };

    case types.DELETE_PARENT_SUCCESS:
      return {
        ...state,
        isDeletingParent: false,
        parentDeleted: true,
      };

    case types.DELETE_PARENT_FAILURE:
      return {
        ...state,
        isDeletingParent: false,
      };

    case types.RESET_PARENT_DELETED:
      return {
        ...state,
        parentDeleted: false,
      };

    case types.GET_STUDENT_PARENTS_REQUEST:
      return {
        ...state,
        isLoadingStudentParents: true,
      };

    case types.GET_STUDENT_PARENTS_SUCCESS:
      return {
        ...state,
        isLoadingStudentParents: false,
        studentParents: action.payload,
      };

    case types.GET_STUDENT_PARENTS_FAILURE:
      return {
        ...state,
        isLoadingStudentParents: false,
      };

    case types.GET_PARENT_REQUEST:
      return {
        ...state,
        isLoadingParent: true,
      };

    case types.GET_PARENT_SUCCESS:
      return {
        ...state,
        isLoadingParent: false,
        parentLoaded: true,
        parent: action.payload,
      };

    case types.GET_PARENT_FAILURE:
      return {
        ...state,
        isLoadingParent: false,
      };

    case types.RESET_PARENT_LOADED:
      return {
        ...state,
        parentLoaded: false,
      };

    case types.RESET_PARENT_EXISTS:
      return {
        ...state,
        parentExists: false,
      };

    default:
      return state;
  }
};
