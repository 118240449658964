import React, { useEffect, useState } from "react";
import { Divider, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import LinearProgressWithLabel from "components/reusable/LinearProgressWithLabel";

const initialProgress = {
  sentEmails: 0,
  totalEmails: 0,
};

const ModalSendingNoticesProgress = ({ open }) => {
  const [sendingProgress, setSendingProgress] = useState(initialProgress);

  useEffect(async () => {
    const connection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_EMAIL_PROGRESS_HUB_URL)
      .configureLogging(LogLevel.Information)
      .build();
    connection.on("ReceiveProgress", (progress) => {
      console.log("Progress", progress);
      setSendingProgress(progress);
    });
    await connection.start();
    return () => {
      connection.stop();
    };
  }, []);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <MDBox
        width={600}
        py={2}
        px={2}
        style={{ background: "white", outline: "none", borderRadius: 8 }}
      >
        <MDTypography>Envoi de l'annonce aux destinataires en cours...</MDTypography>
        <Divider />
        <LinearProgressWithLabel
          value={
            sendingProgress.totalEmails === 0
              ? 0
              : (sendingProgress.sentEmails * 100) / sendingProgress.totalEmails
          }
          text={`${sendingProgress.sentEmails}/${sendingProgress.totalEmails}`}
        />
      </MDBox>
    </Modal>
  );
};

export default ModalSendingNoticesProgress;
