import { StatusCodes } from "http-status-codes";
import Api from "../../api/Api";
import * as types from "../types";
import { navRoutes } from "../../constants/routes";
import { showSuccessAlert } from "./alertActions";
import history from "../../router/router-history";
import { getUrlWithQueryParams } from "../../utils/queryBuilder";

export const addParent = (data) => async (dispatch) => {
  const url = "/parents";
  console.log("data", data);
  try {
    dispatch({ type: types.ADD_PARENT_REQUEST });
    const response = await Api.post(url, data);
    console.log("Response AddParent", response.data);

    dispatch({ type: types.ADD_PARENT_SUCCESS, payload: response.data });
    dispatch(
      showSuccessAlert({
        title: "Création effectuée",
        content: "Parent créé avec succès.",
      })
    );
    history.push(navRoutes.PARENTS);
  } catch (e) {
    console.log("Error handling addParent action ", e);
    dispatch({ type: types.ADD_PARENT_FAILURE });
    const { status } = e.response;
    if (status === StatusCodes.CONFLICT) {
      dispatch({ type: types.ADD_OR_UPDATE_PARENT_FAILURE_CONFLICT });
    }
  }
};

export const getAllParents =
  ({ classId, searchQuery } = {}) =>
  async (dispatch) => {
    const url = getUrlWithQueryParams("/parents", { classId, searchQuery });
    try {
      dispatch({ type: types.GET_ALL_PARENTS_REQUEST });

      const response = await Api.get(url);
      console.log("Res GetAllParents ", response.data);
      dispatch({ type: types.GET_ALL_PARENTS_SUCCESS, payload: response.data });
    } catch (e) {
      console.log("Error handling getAllParents action ", e);
      dispatch({ type: types.GET_ALL_PARENTS_FAILURE });
    }
  };

export const deleteParent = (id) => async (dispatch) => {
  const url = `/parents/${id}`;
  try {
    dispatch({ type: types.DELETE_PARENT_REQUEST });

    const response = await Api.delete(url);
    console.log("Res DeleteParent ", response.data);
    dispatch({ type: types.DELETE_PARENT_SUCCESS, payload: response.data });
    dispatch(
      showSuccessAlert({
        title: "Suppression effectuée",
        content: "Parent supprimé avec succès.",
      })
    );
    dispatch({ type: types.TOGGLE_MODAL_CONFIRM_DELETE, payload: { toggle: false } });
    dispatch({ type: types.RESET_PARENT_DELETED });
    dispatch(getAllParents());
    history.push(navRoutes.PARENTS);
  } catch (e) {
    console.log("Error handling DeleteParent action ", e);
    dispatch({ type: types.DELETE_PARENT_FAILURE });
  }
};

export const getStudentParents = (studentId) => async (dispatch) => {
  const url = `/parents/student/${studentId}`;
  try {
    dispatch({ type: types.GET_STUDENT_PARENTS_REQUEST });
    const res = await Api.get(url);
    console.log("Res getStudentParents", res.data);
    dispatch({ type: types.GET_STUDENT_PARENTS_SUCCESS, payload: res.data });
  } catch (e) {
    console.log("Error handling getStudentParents ", e);
    dispatch({ type: types.GET_STUDENT_PARENTS_FAILURE });
  }
};

export const getParent = (id) => async (dispatch) => {
  const url = `parents/${id}`;
  try {
    dispatch({ type: types.GET_PARENT_REQUEST });
    const res = await Api.get(url, { id });
    console.log("Res getParent", res.data);
    dispatch({ type: types.GET_PARENT_SUCCESS, payload: res.data });
  } catch (e) {
    console.log("Error handling getParent action", e);
    dispatch({ type: types.GET_PARENT_FAILURE });
  }
};

export const updateParent = (newParent) => async (dispatch) => {
  const url = "parents/edit";
  try {
    dispatch({ type: types.UPDATE_PARENT_REQUEST });
    const res = await Api.post(url, newParent);
    console.log("Res updateParent", res.data);
    dispatch({ type: types.UPDATE_PARENT_SUCCESS });
    dispatch(
      showSuccessAlert({
        title: "Modification effectuée",
        content: "Parent modifié avec succès.",
      })
    );
    dispatch({ type: types.TOGGLE_MODAL_EDIT_PARENT, payload: { toggle: false } });
    dispatch(getAllParents());
    history.push(navRoutes.PARENTS);
  } catch (e) {
    console.log("Error handling updateParent action", e);
    dispatch({ type: types.UPDATE_PARENT_FAILURE });
    const { status } = e.response;
    if (status === StatusCodes.CONFLICT) {
      dispatch({ type: types.ADD_OR_UPDATE_PARENT_FAILURE_CONFLICT });
    }
  }
};

export const resetParentExistsFlag = () => (dispatch) => {
  dispatch({ type: types.RESET_PARENT_EXISTS });
};
