export const navRoutes = {
  STUDENTS: "/students",
  NEW_STUDENT: "/students/new",
  PARENTS: "/parents",
  NEW_PARENT: "/parents/new",
  REPORT_CARDS: "/reportCards",
  NEW_REPORT_CARD: "/reportCards/new",
  NEW_NOTICE: "/notices/new",
  NOTICES: "/notices",
  LOGIN: "/login",
  REGISTER: "/register",
  CLASSES: "/classes",
  NEW_CLASS: "/classes/new",
  SCHOOL_YEARS: "/schoolYears",
  NEW_SCHOOL_YEAR: "/schoolYears/new",
  TEACHERS: "/teachers",
  NEW_TEACHER: "/teachers/new",
};
