/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ModalConfirmDelete from "components/reusable/ModalConfirmDelete";
import TableCircularLoader from "components/reusable/TableCircularLoader";
import TableEmptyState from "components/reusable/TableEmptyState";

import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import MDInput from "components/MDInput";
import _ from "lodash";

// Data
import allParentsData from "./data/allParentsData";

import { navRoutes } from "../../constants/routes";

import * as types from "../../store/types";
import * as action from "../../store/actions";
import ModalEditParent from "./items/ModalEditParent";

function ParentsList() {
  const { columns, rows } = allParentsData();

  const [filterClasses, setFilterClasses] = useState([]);
  const [selectedFilterClass, setSelectedFilterClass] = useState(null);
  const [searchText, setSearchText] = useState("");

  const { isDeletingParent, isLoadingParents, parentsLoaded } = useSelector(
    (state) => state.parents
  );
  const { toggleModalEditParent, toggleModalConfirmDelete, entityToDeleteId } = useSelector(
    (state) => state.modals
  );

  const { classes } = useSelector((state) => state.schoolClasses);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action.getAllClasses());
    return () => dispatch(action.resetParentExistsFlag());
  }, []);

  useEffect(() => {
    if (classes && classes.length > 0) {
      let classesOptions = [];
      classes.map((schoolClass) => {
        classesOptions.push({ id: schoolClass.id, label: schoolClass.className });
      });

      setFilterClasses(classesOptions);
    }
  }, [classes]);

  const handleCloseConfirmDeleteModal = () => {
    dispatch({
      type: types.TOGGLE_MODAL_CONFIRM_DELETE,
      payload: { toggle: false },
    });
  };

  const handleDeleteParent = () => {
    console.log("Entity", entityToDeleteId);
    dispatch(action.deleteParent(entityToDeleteId));
  };

  const handleSearchParent = (e) => {
    console.log("Search", e.target.value);
    setSearchText(e.target.value);
    dispatch(
      action.getAllParents({ classId: selectedFilterClass?.id, searchQuery: e.target.value })
    );
  };

  const handleFilterClassChange = (event, value) => {
    const id = value?.id;
    console.log("ValueId", value?.id);
    setSelectedFilterClass(value);
    dispatch(action.getAllParents({ classId: id, searchQuery: searchText }));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Liste des parents
                </MDTypography>

                <MDButton component={Link} to={navRoutes.NEW_PARENT}>
                  <MDBox display="flex" flexDirection="row" alignItems="center">
                    <Icon fontSize="small">add</Icon>
                    Ajouter
                  </MDBox>
                </MDButton>
              </MDBox>

              <MDBox pt={3} mx={2} display="flex" alignItems="center">
                <MDInput
                  sx={{ flex: 0.5 }}
                  label="Recherche"
                  InputProps={{ style: { paddingTop: 3, paddingBottom: 3 } }}
                  onChange={_.debounce((e) => handleSearchParent(e), 500)}
                />
                <MDBox sx={{ flex: 1 }} display="flex" justifyContent="flex-end">
                  <Autocomplete
                    sx={{ flex: 0.5 }}
                    disablePortal
                    id="combo-box"
                    options={filterClasses}
                    renderInput={(params) => <TextField {...params} label="Classe" />}
                    value={selectedFilterClass || null}
                    onChange={(event, newValue) => {
                      handleFilterClassChange(event, newValue);
                    }}
                  />
                </MDBox>
              </MDBox>

              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                <TableCircularLoader isLoading={isLoadingParents} />
                <TableEmptyState
                  isEmpty={rows.length === 0 && parentsLoaded}
                  text="Aucun parent disponible"
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {toggleModalEditParent && (
        <ModalEditParent
          open={toggleModalEditParent}
          onClose={() =>
            dispatch({
              type: types.TOGGLE_MODAL_EDIT_PARENT,
              payload: { toggleModalEditParent: false },
            })
          }
        />
      )}

      <ModalConfirmDelete
        title="Confirmation de suppression"
        message="Êtes-vous certain de vouloir supprimer ce parent ?"
        open={toggleModalConfirmDelete}
        onClose={handleCloseConfirmDeleteModal}
        onConfirm={handleDeleteParent}
        isLoading={isDeletingParent}
      />

      <Footer />
    </DashboardLayout>
  );
}

export default ParentsList;
