import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Icon,
  Modal,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as action from "../../../store/actions";
import classStudents from "../data/classStudents";

const ModalTransferStudents = () => {
  const [selectedSourceClass, setSelectedSourceClass] = useState(null);
  const [selectedDestClass, setSelectedDestClass] = useState(null);
  const [dropDownSourceClassesOptions, setDropDownClassesOptions] = useState([]);
  const [sourceClassesDropDownBlurred, setSourceClassesDropDownBlurred] = useState(false);
  const [destClassesDropDownBlurred, setDestClassesDropDownBlurred] = useState(false);
  const [dropDownDestClassesOptions, setDropDownDestClassesOptions] = useState([]);
  const [isCustomTransfer, setIsCustomTransfer] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);

  const { toggleModalTransferStudents, modalSourceClassId } = useSelector((state) => state.modals);
  const { classes, isTransferringStudents } = useSelector((state) => state.schoolClasses);

  const { columns, rows } = classStudents(
    selectedSourceClass?.students || [],
    (ids) => onChangeSelection(ids),
    toggleModalTransferStudents
  );

  console.log("SchoolClasses", classes);

  const dispatch = useDispatch();

  useEffect(() => {
    getSourceClass();
    getDestinationClasses();
  }, [modalSourceClassId]);

  useEffect(() => {
    if (!toggleModalTransferStudents) {
      setIsCustomTransfer(false);
      setSelectedDestClass(null);
    }
  }, [toggleModalTransferStudents]);

  const getSourceClass = () => {
    const sourceClass = classes && classes.find((item) => item.id === modalSourceClassId);
    console.log("SourceClass", sourceClass);

    if (sourceClass) {
      setSelectedSourceClass({
        ...sourceClass,
        label: sourceClass.className,
      });
    }
  };

  const getDestinationClasses = () => {
    const destinationClasses = classes && classes.filter((item) => item.id !== modalSourceClassId);
    console.log("DestinationClasses", destinationClasses);
    let destinationClassesOptions = [];
    destinationClasses.map((schoolClass) => {
      destinationClassesOptions.push({ id: schoolClass.id, label: schoolClass.className });
    });

    setDropDownDestClassesOptions(destinationClassesOptions);
  };

  const handleConfirmTransfer = () => {
    const transferObject = {
      srcClassId: selectedSourceClass.id,
      destClassId: selectedDestClass.id,
      isCustomTransfer,
      students: isCustomTransfer ? selectedStudents : [],
    };

    console.log("TransferObject,", transferObject);
    dispatch(action.transferStudents(transferObject));
  };

  const handleClose = () => {
    dispatch(action.closeModalTransferStudents());
  };

  const onChangeSelection = (ids) => {
    console.log("onChangeSelection", ids);
    setSelectedStudents(ids);
  };

  return (
    <Modal
      open={toggleModalTransferStudents}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box width={600} style={{ background: "white", outline: "none", borderRadius: 8 }}>
        <MDBox mt={3} mb={3}>
          <Grid item xs={12} lg={12}>
            <MDBox p={3} display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="h5">Transfert d'élèves</MDTypography>
              <MDButton iconOnly onClick={handleClose}>
                <Icon fontSize="large">close</Icon>
              </MDButton>
            </MDBox>
            <MDBox pt={2} display="flex" alignItems="center" px={3}>
              <MDBox display="flex" sx={{ flex: 1 }} pr={1}>
                <Autocomplete
                  sx={{ flex: 1 }}
                  disablePortal
                  id="combo-box"
                  onBlur={setSourceClassesDropDownBlurred}
                  options={[selectedSourceClass]}
                  renderInput={(params) => <TextField {...params} label="Classe source" />}
                  value={selectedSourceClass || null}
                  onChange={(event, newValue) => {
                    setSelectedSourceClass(newValue);
                  }}
                  disabled
                />
              </MDBox>
              <MDBox display="flex" sx={{ flex: 1 }} pl={1}>
                <Autocomplete
                  sx={{ flex: 1 }}
                  disablePortal
                  id="combo-box"
                  onBlur={setDestClassesDropDownBlurred}
                  options={dropDownDestClassesOptions}
                  renderInput={(params) => <TextField {...params} label="Classe destination" />}
                  value={selectedDestClass || null}
                  onChange={(event, newValue) => {
                    setSelectedDestClass(newValue);
                  }}
                />
              </MDBox>
            </MDBox>

            <MDBox px={3} pt={3}>
              <MDBox display="flex" flexDirection="row" alignItems="center">
                <input
                  type="radio"
                  name="isCustomTransfer"
                  onChange={() => setIsCustomTransfer(false)}
                  checked={isCustomTransfer === false}
                  style={{ height: 15, width: 15, marginRight: 10 }}
                />
                <MDTypography
                  fontSize="medium"
                  fontWeight="bold"
                  pl={1}
                  onClick={() => setIsCustomTransfer(false)}
                >
                  Transférer tous les élèves
                </MDTypography>
              </MDBox>

              <MDBox display="flex" flexDirection="row" alignItems="center" mt={2}>
                <input
                  type="radio"
                  name="customTransfer"
                  onChange={() => setIsCustomTransfer(true)}
                  checked={isCustomTransfer}
                  style={{ height: 15, width: 15, marginRight: 10 }}
                />
                <MDTypography
                  fontSize="medium"
                  fontWeight="bold"
                  pl={1}
                  onClick={() => setIsCustomTransfer(true)}
                >
                  Transfert personnalisé
                </MDTypography>
              </MDBox>
            </MDBox>

            {isCustomTransfer && (
              <MDBox py={2} sx={{ maxHeight: 200, overflowY: "scroll" }}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={{ defaultValue: 50 }}
                  showTotalEntries={false}
                  noEndBorder
                  checkboxSelection
                />
              </MDBox>
            )}

            <Divider />
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDButton
                onClick={handleConfirmTransfer}
                disabled={
                  isTransferringStudents ||
                  selectedDestClass === null ||
                  selectedSourceClass === null ||
                  (isCustomTransfer && selectedStudents.length === 0)
                }
                variant="gradient"
                color="info"
                style={{
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                {isTransferringStudents ? (
                  <CircularProgress color="white" size={20} />
                ) : (
                  "Transférer"
                )}
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Box>
    </Modal>
  );
};

export default ModalTransferStudents;
