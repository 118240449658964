import axios from "axios";
import { StatusCodes } from "http-status-codes";
import { navRoutes } from "../constants/routes";
import { configuredStore } from "../store/configStore";
import { showErrorAlert } from "../store/actions";
import history from "../router/router-history";

const Api = () => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  api.interceptors.response.use(
    (response) => response,

    (error) => {
      const status = error.response && error.response.status;
      if (status === StatusCodes.INTERNAL_SERVER_ERROR) {
        configuredStore.dispatch(
          showErrorAlert({
            title: "Erreur interne",
            content: "Un problème est survenu. Réessayez ultérieurement.",
          })
        );
      }

      if (status === StatusCodes.UNAUTHORIZED) {
        history.replace(navRoutes.LOGIN, { isUnauthorized: true });
      }

      if (error.message === "Network Error") {
        configuredStore.dispatch(
          showErrorAlert({
            title: "Erreur de connexion",
            content: "Impossible d'établir la connexion avec le serveur.",
          })
        );
      }

      return Promise.reject(error);
    }
  );
  return api;
};

export default Api();
