import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Autocomplete,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ErrorMessage from "components/reusable/ErrorMessage";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDInput from "components/MDInput";
import ReactQuill from "react-quill";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { navRoutes } from "constants/routes";
import { hasValue, exceededSmsLength } from "../../utils/helpers";
import ModalSendingNoticesProgress from "./items/ModalSendingNoticesProgress";

import { smsMaxLength } from "../../constants/sms";
import * as action from "../../store/actions";
import { RECEIVER_TYPE } from "./utils/receiver-types";
import { noticeValidationSchema } from "./validation-schema/noticeValidationSchema";
import * as types from "../../store/types";
import ModalConfirmSendNotice from "./items/ModalConfirmSendNotice";

const INITIAL_VALUES = {
  subject: "",
  type: "",
  receiver: "",
  htmlContent: "",
  attachedFiles: [],
};

function NewNotice() {
  const [data, setData] = useState(INITIAL_VALUES);

  const [dropDownClassesOptions, setDropDownClassesOptions] = useState([]);
  const [dropDownStudentsOptions, setDropDownStudentsOptions] = useState([]);
  const [dropDownTeachersOptions, setDropDownTeachersOptions] = useState([]);

  const [classesDropDownBlurred, setClassesDropDownBlurred] = useState(false);
  const [studentsDropDownBlurred, setStudentsDropDownBlurred] = useState(false);
  const [teachersDropDownBlurred, setTeachersDropDownBlurred] = useState(false);

  const [selectedReceiverType, setSelectedReceiverType] = useState(RECEIVER_TYPE.EVERYONE);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [studentsWithoutParents, setStudentsWithoutParents] = useState([]);

  const [viaEmail, setViaEmail] = useState(true);
  const [viaSms, setViaSms] = useState(false);

  const [exceededMaxLength, setExceededMaxLength] = useState(false);
  const [showModalConfirmSendNotice, setShowModalConfirmSendNotice] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const { isAddingNotice } = useSelector((state) => state.notices);
  const { classes, isLoadingClasses, classesLoaded } = useSelector((state) => state.schoolClasses);
  const { students, isLoadingStudents, studentsLoaded } = useSelector((state) => state.students);
  const { teachers, isLoadingTeachers, teachersLoaded } = useSelector((state) => state.teachers);
  const { toggleModalSendingNoticesProgress } = useSelector((state) => state.modals);

  const dispatch = useDispatch();

  let quillRef = useRef();

  const imageHandler = async () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append("file", file);

      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();
      console.log("Editor", editor);
      console.log("Selection", editor.getSelection());

      editor.insertEmbed(range.index, "image", `${window.location.origin}/images/placeholder.gif`);

      editor.formatText(range.index, 1, "width", 200);
      editor.formatText(range.index, 1, "height", 150);
      editor.setSelection(range.index + 1);

      const res = await action.uploadFile(formData);
      console.log("Res", res);

      editor.deleteText(range.index, 1);
      editor.insertEmbed(range.index, "image", res.url);
      editor.formatText(range.index, 1, "width", 200);
      editor.formatText(range.index, 1, "height", 150);
    };
  };

  const quillModules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ align: [] }],
          ["link", "image"],
          ["clean"],
          [{ color: [] }],
          [{ direction: "rtl" }],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    if (
      selectedReceiverType === RECEIVER_TYPE.BY_CLASS ||
      selectedReceiverType === RECEIVER_TYPE.BY_TEACHER_CLASS
    ) {
      dispatch(action.getAllClasses());
    }

    if (selectedReceiverType === RECEIVER_TYPE.BY_STUDENT) {
      dispatch(action.getAllStudents());
    }

    if (selectedReceiverType === RECEIVER_TYPE.BY_TEACHER) {
      dispatch(action.getAllTeachers());
    }
  }, [selectedReceiverType]);

  useEffect(() => {
    if (classesLoaded) {
      let options = [];
      classes.map((schoolClass) => {
        options.push({ id: schoolClass.id, label: schoolClass.className });
      });
      setDropDownClassesOptions(options);
    }
  }, [classes, classesLoaded]);

  useEffect(() => {
    if (studentsLoaded) {
      let options = [];
      students.map((student) => {
        const fullName = `${student.firstName} ${student.lastName}`;
        const label = `${fullName} - ${student.schoolClass.className}`;
        options.push({ id: student.id, label, fullName, hasParents: student.hasParents });
      });
      setDropDownStudentsOptions(options);
    }
  }, [students, studentsLoaded]);

  useEffect(() => {
    if (teachersLoaded) {
      let options = [];
      teachers.map((teacher) => {
        const label = `${teacher.firstName} ${teacher.lastName}`;
        options.push({ id: teacher.id, label });
      });
      setDropDownTeachersOptions(options);
    }
  }, [teachers, teachersLoaded]);

  useEffect(() => {
    checkNoticeTextLength(data.htmlContent);
  }, [data.attachedFiles]);

  const resetForm = () => {
    setData({
      ...INITIAL_VALUES,
    });
  };

  const handleOpenModalSendingProgress = () => {
    dispatch({ type: types.TOGGLE_MODAL_SENDING_NOTICES_PROGRESS, payload: { toggle: true } });
  };

  const handleCloseModalSendingProgress = () => {
    dispatch({
      type: types.TOGGLE_MODAL_SENDING_NOTICES_PROGRESS,
      payload: { toggle: false },
    });
  };

  const handleAddNotice = () => {
    const subject = formValues && formValues.subject;
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("receiverType", selectedReceiverType);
    formData.append("jsonStudents", JSON.stringify([...selectedStudents]));
    formData.append("jsonClasses", JSON.stringify([...selectedClasses]));
    formData.append("jsonTeachers", JSON.stringify([...selectedTeachers]));
    formData.append("content", data.htmlContent);
    formData.append("viaSms", viaSms);
    formData.append("viaEmail", viaEmail);

    data.attachedFiles.forEach((file) => formData.append("files", file));

    setShowModalConfirmSendNotice(false);
    handleOpenModalSendingProgress();
    dispatch(action.sendNotice(formData));
  };

  const checkNoticeTextLength = (content) => {
    const hasExceededMaxLength = exceededSmsLength(content, data.attachedFiles.length > 0);
    setExceededMaxLength(hasExceededMaxLength);
  };

  const handleQuillContentChange = (e) => {
    setData({
      ...data,
      htmlContent: e,
    });

    checkNoticeTextLength(e);
  };

  const handleChangeFiles = (e) => {
    if (e.target.files) {
      let attachedFiles = [];
      Object.keys(e.target.files).forEach((key) => {
        attachedFiles.push(e.target.files[key]);
      });
      setData({
        ...data,
        attachedFiles,
      });
    } else {
      setData({
        ...data,
        attachedFiles: [],
      });
    }
  };

  const handleChangeSelectedStudents = (event, newValue) => {
    console.log("NewVa", newValue);
    const withoutParents = newValue.filter((student) => !student.hasParents);
    setStudentsWithoutParents(withoutParents);
    setSelectedStudents(newValue);
  };

  const getConfirmSendNoticeMessage = () => {
    let message = "Êtes-vous certain de vouloir envoyer une annonce ";
    if (data.attachedFiles?.length > 0) {
      message += `avec ${data.attachedFiles.length} pièce(s) jointe(s) `;
    }

    if (data.attachedFiles?.length === 0) {
      message += "sans pièces jointes ";
    }

    if (selectedReceiverType === RECEIVER_TYPE.EVERYONE) {
      message += "à tout le monde ";
    }

    if (selectedReceiverType === RECEIVER_TYPE.ALL_PARENTS) {
      message += "à tous les parents ";
    }

    if (selectedReceiverType === RECEIVER_TYPE.ALL_TEACHERS) {
      message += "à tous les enseignants ";
    }

    if (selectedReceiverType === RECEIVER_TYPE.BY_CLASS) {
      message += selectedClasses.length > 1 ? "aux classes : " : "à la classe : ";
      selectedClasses.map((schoolClass, index) => {
        message += schoolClass.label;
        message += index + 1 < selectedClasses.length ? ", " : " ";
      });
    }

    if (selectedReceiverType === RECEIVER_TYPE.BY_STUDENT) {
      message += "aux parents ";
      message += selectedStudents.length > 1 ? "des élèves : " : "de l'élève : ";
      selectedStudents.map((student, index) => {
        message += student.label;
        message += index + 1 < selectedStudents.length ? ", " : " ";
      });
    }

    if (selectedReceiverType === RECEIVER_TYPE.BY_TEACHER_CLASS) {
      message += "aux enseignants ";
      message += selectedClasses.length > 1 ? "des classes : " : "de la classe : ";
      selectedClasses.map((schoolClass, index) => {
        message += schoolClass.label;
        message += index + 1 < selectedClasses.length ? ", " : " ";
      });
    }

    if (selectedReceiverType === RECEIVER_TYPE.BY_TEACHER) {
      message += selectedTeachers.length > 1 ? "aux enseignants : " : "à l'enseignant(e) : ";
      selectedTeachers.map((teacher, index) => {
        message += teacher.label;
        message += index + 1 < selectedTeachers.length ? ", " : " ";
      });
    }

    message += "?";

    return message;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={{ subject: "" }}
              validationSchema={noticeValidationSchema}
              onSubmit={(values) => {
                setFormValues(values);
                setShowModalConfirmSendNotice(true);
              }}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <Card>
                    <MDBox p={2}>
                      <MDTypography variant="h5">Création d'une nouvelle annonce</MDTypography>
                    </MDBox>

                    {/* Subject */}
                    <MDBox sx={{ mx: 2 }}>
                      <MDInput
                        label="Sujet"
                        name="subject"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.subject}
                        error={
                          props.touched.subject &&
                          props.errors.subject &&
                          props.errors.subject !== ""
                        }
                        fullWidth
                      />
                      <ErrorMessage message={props.touched.subject && props.errors.subject} />
                    </MDBox>

                    {/* Type */}
                    <FormControl sx={{ mx: 2, mt: 2 }}>
                      <InputLabel id="typeSelectLabel">Type</InputLabel>
                      <Select
                        placeholder="Type"
                        id="typeSelect"
                        labelId="typeSelectLabel"
                        label="Type"
                        name="type"
                        onChange={(e) => {
                          handleInputChange(e);
                          setSelectedReceiverType(e.target.value);
                          setSelectedClasses([]);
                          setSelectedStudents([]);
                          setSelectedTeachers([]);
                          setStudentsWithoutParents([]);
                        }}
                        value={data.type || 0}
                        fullWidth
                        IconComponent={() => (
                          <MDBox iconOnly pr={1}>
                            <Icon fontSize="medium" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                              arrow_drop_down
                            </Icon>
                          </MDBox>
                        )}
                        style={{ paddingTop: 15, paddingBottom: 10 }}
                        variant="outlined"
                      >
                        <MenuItem value={RECEIVER_TYPE.EVERYONE}>A tout le monde</MenuItem>
                        <MenuItem value={RECEIVER_TYPE.ALL_PARENTS}>A tous les parents</MenuItem>
                        <MenuItem value={RECEIVER_TYPE.BY_CLASS}>Par classe</MenuItem>
                        <MenuItem value={RECEIVER_TYPE.BY_STUDENT}>Par élève</MenuItem>
                        <MenuItem value={RECEIVER_TYPE.ALL_TEACHERS}>
                          A tous les enseignants
                        </MenuItem>
                        <MenuItem value={RECEIVER_TYPE.BY_TEACHER_CLASS}>
                          Par classes d'enseignants
                        </MenuItem>
                        <MenuItem value={RECEIVER_TYPE.BY_TEACHER}>Par enseignant</MenuItem>
                      </Select>
                    </FormControl>

                    {/* Receiver */}
                    {(selectedReceiverType === RECEIVER_TYPE.BY_CLASS ||
                      selectedReceiverType === RECEIVER_TYPE.BY_TEACHER_CLASS) && (
                      <MDBox sx={{ mx: 2, mt: 2 }}>
                        <Autocomplete
                          disablePortal
                          multiple
                          id="combo-box"
                          onBlur={setClassesDropDownBlurred}
                          options={dropDownClassesOptions}
                          renderInput={(params) => <TextField {...params} label="Classe(s)" />}
                          value={selectedClasses || []}
                          onChange={(event, newValue) => {
                            setSelectedClasses([...newValue]);
                          }}
                        />
                        <ErrorMessage
                          message={
                            selectedClasses.length === 0 &&
                            classesDropDownBlurred &&
                            "Vous devez sélectionner au moins une classe."
                          }
                        />
                      </MDBox>
                    )}

                    {/* Receiver */}
                    {selectedReceiverType === RECEIVER_TYPE.BY_STUDENT && (
                      <MDBox sx={{ mx: 2, mt: 2 }}>
                        <Autocomplete
                          disablePortal
                          multiple
                          id="combo-box"
                          onBlur={setStudentsDropDownBlurred}
                          options={dropDownStudentsOptions}
                          renderInput={(params) => <TextField {...params} label="Elève(s)" />}
                          value={selectedStudents || []}
                          // onChange={(event, newValue) => {
                          //   setSelectedStudents([...newValue]);
                          // }}
                          onChange={(event, newValue) => {
                            handleChangeSelectedStudents(event, [...newValue]);
                          }}
                        />
                        <ErrorMessage
                          message={
                            selectedStudents.length === 0 &&
                            studentsDropDownBlurred &&
                            "Vous devez sélectionner au moins un élève."
                          }
                        />

                        {studentsWithoutParents?.length > 0 && (
                          <ErrorMessage
                            message={
                              <MDTypography
                                variant="caption"
                                color="error"
                                display="block"
                                style={{ paddingTop: 12, paddingBottom: 5 }}
                              >
                                Certains élèves sélectionnés n'ont pas de parents.
                                <Link
                                  to={navRoutes.NEW_PARENT}
                                  style={{
                                    textDecoration: "none",
                                    color: "#F44335",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {" "}
                                  Veuillez leurs en créer.
                                </Link>
                              </MDTypography>
                            }
                          />
                        )}
                        {studentsWithoutParents?.map((student) => (
                          <MDTypography variant="caption" color="error" display="block">
                            - {student.label}
                          </MDTypography>
                        ))}
                      </MDBox>
                    )}

                    {/* Receiver */}
                    {selectedReceiverType === RECEIVER_TYPE.BY_TEACHER && (
                      <MDBox sx={{ mx: 2, mt: 2 }}>
                        <Autocomplete
                          disablePortal
                          multiple
                          id="combo-box"
                          onBlur={setTeachersDropDownBlurred}
                          options={dropDownTeachersOptions}
                          renderInput={(params) => <TextField {...params} label="Enseignant(s)" />}
                          value={selectedTeachers || []}
                          onChange={(event, newValue) => {
                            setSelectedTeachers([...newValue]);
                          }}
                        />
                        <ErrorMessage
                          message={
                            selectedTeachers.length === 0 &&
                            teachersDropDownBlurred &&
                            "Vous devez sélectionner au moins un enseignant."
                          }
                        />
                      </MDBox>
                    )}

                    {viaEmail && (
                      <MDBox sx={{ mx: 2, mt: 2 }}>
                        <MDInput
                          label="Pièce(s) jointe(s)"
                          type="file"
                          inputProps={{ multiple: true }}
                          onChange={handleChangeFiles}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </MDBox>
                    )}

                    <MDBox sx={{ mx: 2, mt: 2 }}>
                      <InputLabel style={{ fontSize: 13 }}>Méthodes</InputLabel>
                      <FormGroup>
                        <MDBox display="flex" flexDirection="row" sx={{ mx: 2 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={viaEmail}
                                onChange={() => setViaEmail(!viaEmail)}
                              />
                            }
                            label="Email"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox checked={viaSms} onChange={() => setViaSms(!viaSms)} />
                            }
                            label="SMS"
                          />
                        </MDBox>
                      </FormGroup>
                      <ErrorMessage
                        message={
                          !viaEmail && !viaSms && "Vous devez sélectionner au moins une méthode."
                        }
                      />
                    </MDBox>

                    {/* Quill */}
                    <MDBox pt={2} px={2}>
                      <div>
                        <ReactQuill
                          ref={quillRef}
                          modules={quillModules}
                          theme="snow"
                          onChange={handleQuillContentChange}
                        />
                      </div>
                      <ErrorMessage
                        message={
                          viaSms &&
                          exceededMaxLength &&
                          `Vous avez dépassé la limite du nombre de caractères autorisés pour les SMS : ${
                            data.attachedFiles.length > 0
                              ? smsMaxLength.withAttachments
                              : smsMaxLength.withoutAttachments
                          } caractères`
                        }
                      />
                    </MDBox>

                    <MDBox
                      mt={4}
                      mb={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      backgroundColor="blue"
                    >
                      <MDButton
                        type="submit"
                        variant="gradient"
                        color="info"
                        style={{
                          paddingLeft: "50px",
                          paddingRight: "50px",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        disabled={
                          hasValue(props.errors) ||
                          data.htmlContent === "" ||
                          (!viaSms && !viaEmail) ||
                          (viaSms && exceededMaxLength) ||
                          studentsWithoutParents?.length > 0
                        }
                      >
                        {isAddingNotice ? <CircularProgress color="white" size={20} /> : "Créer"}
                      </MDButton>
                    </MDBox>
                  </Card>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>

      {/* Only show progress when Email method is selected */}
      {toggleModalSendingNoticesProgress && viaEmail && (
        <ModalSendingNoticesProgress
          open={toggleModalSendingNoticesProgress && viaEmail}
          onClose={handleCloseModalSendingProgress}
        />
      )}

      {showModalConfirmSendNotice && (
        <ModalConfirmSendNotice
          open={showModalConfirmSendNotice}
          onConfirm={handleAddNotice}
          message={getConfirmSendNoticeMessage()}
          onClose={() => setShowModalConfirmSendNotice(false)}
        />
      )}

      <Footer />
    </DashboardLayout>
  );
}

export default NewNotice;
