/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { useDispatch, useSelector } from "react-redux";

import ModalConfirmDelete from "components/reusable/ModalConfirmDelete";
import TableCircularLoader from "components/reusable/TableCircularLoader";
import TableEmptyState from "components/reusable/TableEmptyState";

import { hasValue } from "../../utils/helpers";
// Data
import allReportCardsData from "./data/allReportCardsData";

import { navRoutes } from "../../constants/routes";
import * as action from "../../store/actions";
import * as types from "../../store/types";
import ModalConfirmSendAll from "./items/ModalConfirmSendAll";
import ModalChooseReceiver from "./items/ModalChooseReceiver";

const INITIAL_FILTERS = {
  schoolYearId: null,
  trimester: null,
  schoolClassId: null,
  sendStatus: null,
};

function ReportCardsList() {
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [selectedCardsIds, setSelectedCardsIds] = useState([]);

  const { columns, rows } = allReportCardsData();

  const { schoolYears, schoolYearsLoaded } = useSelector((state) => state.schoolYears);
  const {
    reportCards,
    reportCardsLoaded,
    isDeletingReportCard,
    isFirstInit,
    reportCardDeleted,
    isSendingMultipleCards,
    isLoadingReportCards,
  } = useSelector((state) => state.reportCards);

  const {
    toggleModalConfirmDelete,
    entityToDeleteId,
    reportCardId,
    toggleModalChooseReceiver,
    toggleModalConfirmSendAll,
  } = useSelector((state) => state.modals);

  const { classes } = useSelector((state) => state.schoolClasses);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action.getAllSchoolYears());
    dispatch(action.getAllClasses());
  }, []);

  useEffect(() => {
    if (isFirstInit && schoolYearsLoaded && reportCards.length > 0) {
      setFilters({
        schoolYearId: reportCards[0].schoolYear.id,
        trimester: reportCards[0].trimester,
      });
    }
  }, [isFirstInit, reportCardsLoaded, schoolYearsLoaded]);

  useEffect(() => {
    if (reportCards.length > 0) {
      let cardsIds = [];
      reportCards.map((card) => {
        cardsIds.push({ id: card.id });
      });

      setSelectedCardsIds(cardsIds);
    } else {
      setSelectedCardsIds([]);
    }
  }, [reportCards]);

  useEffect(() => {
    if (reportCardDeleted) {
      dispatch(action.getAllReportCards(filters));
    }
  }, [reportCardDeleted]);

  const handleFiltersChange = (e) => {
    const { name, value } = e.target;
    const newFilters = { ...filters, [name]: value };
    setFilters(newFilters);
    dispatch(action.getAllReportCards(newFilters));
  };

  const handleSendAllReportCards = () => {
    console.log("SelectedCards", selectedCardsIds);
    dispatch(action.sendMultipleReportCards(selectedCardsIds));
  };

  const handleCloseConfirmDeleteModal = () => {
    dispatch({ type: types.TOGGLE_MODAL_CONFIRM_DELETE, payload: { toggle: true } });
  };

  const handleDeleteReportCard = () => {
    dispatch(action.deleteReportCard(entityToDeleteId));
  };

  const handleClearFilters = () => {
    setFilters(INITIAL_FILTERS);
    dispatch(action.getAllReportCards());
  };

  const handleCloseModalChooseReceiver = () => {
    dispatch({ type: types.TOGGLE_MODAL_CHOOSE_RECEIVER, payload: { toggle: false } });
  };

  const handleOpenModalConfirmSendAll = () => {
    dispatch({ type: types.TOGGLE_MODAL_CONFIRM_SEND_ALL, payload: { toggle: true } });
  };

  const handleCloseModalConfirmSendAll = () => {
    dispatch({ type: types.TOGGLE_MODAL_CONFIRM_SEND_ALL, payload: { toggle: false } });
  };

  const getSendAllFiltersMessage = () => {
    let message = "Vous êtes sur le point de transmettre tous les bulletins ";

    if (filters.sendStatus === 1) {
      message += "non remis ";
    }

    if (filters.sendStatus === 2) {
      message += "déjà remis ";
    }

    if (filters.schoolClassId) {
      const className = classes.find((item) => item.id === filters.schoolClassId)?.className;
      message += `de la classe ${className} `;
    }

    if (filters.trimester) {
      message += `du trimestre ${filters.trimester} `;
    }

    message += "aux parents des élèves. Voulez-vous vraiment continuer ?";

    console.log("Message", message);

    return message;
  };

  console.log("Selected", selectedCardsIds);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Liste des bulletins
                </MDTypography>

                <MDButton component={Link} to={navRoutes.NEW_REPORT_CARD}>
                  <MDBox display="flex" flexDirection="row" alignItems="center">
                    <Icon fontSize="small">add</Icon>
                    Ajouter
                  </MDBox>
                </MDButton>
              </MDBox>

              <MDBox pt={2} sx={{ mx: 2 }}>
                <Typography variant="h6">Filtrer par : </Typography>
              </MDBox>

              {/* Row Select Dropdowns */}
              <MDBox pt={2} display="flex" flexDirection="row" alignItems="center" fullWidth>
                {/* School Year */}
                <FormControl sx={{ mx: 2 }} fullWidth>
                  <InputLabel id="schoolYearSelectLabel">Année scolaire</InputLabel>
                  <Select
                    placeholder="Année scolaire"
                    id="schoolYearSelect"
                    labelId="schoolYearSelectLabel"
                    label="Année scolaire"
                    name="schoolYearId"
                    onChange={handleFiltersChange}
                    value={filters.schoolYearId || ""}
                    fullWidth
                    IconComponent={() => (
                      <MDBox iconOnly pr={1}>
                        <Icon fontSize="medium" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          arrow_drop_down
                        </Icon>
                      </MDBox>
                    )}
                    style={{ paddingTop: 15, paddingBottom: 10 }}
                    variant="outlined"
                  >
                    {schoolYears.map((year) => (
                      <MenuItem key={year.id} value={year.id}>
                        {year.schoolYear}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Trimester */}
                <FormControl sx={{ mx: 2 }} fullWidth>
                  <InputLabel id="trimesterLabel">Trimestre</InputLabel>
                  <Select
                    placeholder="Trimestre"
                    id="trimesterSelect"
                    labelId="trimesterLabel"
                    label="Trimestre"
                    name="trimester"
                    onChange={handleFiltersChange}
                    value={filters.trimester || ""}
                    fullWidth
                    IconComponent={() => (
                      <MDBox iconOnly pr={1}>
                        <Icon fontSize="medium" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          arrow_drop_down
                        </Icon>
                      </MDBox>
                    )}
                    style={{ paddingTop: 15, paddingBottom: 10 }}
                    variant="outlined"
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                  </Select>
                </FormControl>

                {/* Class */}
                <FormControl sx={{ mx: 2 }} fullWidth>
                  <InputLabel id="classLabel">Classe</InputLabel>
                  <Select
                    placeholder="Classe"
                    id="classSelect"
                    labelId="classLabel"
                    label="Classe"
                    name="schoolClassId"
                    onChange={handleFiltersChange}
                    value={filters.schoolClassId || ""}
                    fullWidth
                    IconComponent={() => (
                      <MDBox iconOnly pr={1}>
                        <Icon fontSize="medium" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          arrow_drop_down
                        </Icon>
                      </MDBox>
                    )}
                    style={{ paddingTop: 15, paddingBottom: 10 }}
                    variant="outlined"
                  >
                    {classes.map((schoolClass) => (
                      <MenuItem key={schoolClass.id} value={schoolClass.id}>
                        {schoolClass.className}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* SendStatus */}
                <FormControl sx={{ mx: 2 }} fullWidth>
                  <InputLabel id="classLabel">Etat</InputLabel>
                  <Select
                    placeholder="Etat"
                    id="sendStatusSelect"
                    labelId="sendStatusLabel"
                    label="Etat"
                    name="sendStatus"
                    onChange={handleFiltersChange}
                    value={filters.sendStatus || ""}
                    fullWidth
                    IconComponent={() => (
                      <MDBox iconOnly pr={1}>
                        <Icon fontSize="medium" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          arrow_drop_down
                        </Icon>
                      </MDBox>
                    )}
                    style={{ paddingTop: 15, paddingBottom: 10 }}
                    variant="outlined"
                  >
                    <MenuItem value={1}>Non remis</MenuItem>
                    <MenuItem value={2}>Remis</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>

              {hasValue(filters) && (
                <MDBox pt={2} display="flex" justifyContent="center" alignItems="center">
                  <MDButton variant="outlined" color="error" onClick={handleClearFilters}>
                    Effacer les filtrer
                  </MDButton>
                </MDBox>
              )}

              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  checkboxSelection
                />
                <TableCircularLoader isLoading={isLoadingReportCards} />
                <TableEmptyState
                  isEmpty={rows.length === 0 && reportCardsLoaded}
                  text="Aucun bulletin disponible"
                />
                {rows.length !== 0 && (
                  <MDBox pt={3} mb={3} display="flex" justifyContent="center" alignItems="center">
                    <MDButton
                      variant="outlined"
                      color="info"
                      onClick={handleOpenModalConfirmSendAll}
                      disabled={selectedCardsIds.length === 0}
                    >
                      Envoyer tout
                    </MDButton>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <ModalConfirmDelete
        title="Confirmation de suppression"
        message="Êtes-vous certain de vouloir supprimer ce bulletin ?"
        open={toggleModalConfirmDelete}
        onClose={handleCloseConfirmDeleteModal}
        onConfirm={handleDeleteReportCard}
        isLoading={isDeletingReportCard}
      />

      {toggleModalConfirmSendAll && (
        <ModalConfirmSendAll
          title="Confirmation d'envoi"
          message={getSendAllFiltersMessage()}
          open={toggleModalConfirmSendAll}
          onClose={handleCloseModalConfirmSendAll}
          onConfirm={handleSendAllReportCards}
          isLoading={isSendingMultipleCards}
          totalEmailsCount={selectedCardsIds.length}
        />
      )}

      {toggleModalChooseReceiver && (
        <ModalChooseReceiver
          reportCardId={reportCardId}
          open={toggleModalChooseReceiver}
          onClose={handleCloseModalChooseReceiver}
        />
      )}

      <Footer />
    </DashboardLayout>
  );
}

export default ReportCardsList;
