import * as types from "../types";

export const closeModalNoticeDetails = () => (dispatch) => {
  dispatch({ type: types.TOGGLE_MODAL_NOTICE_DETAILS, payload: { toggle: false } });
};

export const openModalNoticeDetails = (id) => (dispatch) => {
  dispatch({ type: types.TOGGLE_MODAL_NOTICE_DETAILS, payload: { toggle: true, id } });
};

export const openModalDeleteClassStudents = (classId) => (dispatch) => {
  dispatch({
    type: types.TOGGLE_MODAL_DELETE_CLASS_STUDENTS,
    payload: { toggle: true, modalClassId: classId },
  });
};

export const closeModalDeleteClassStudents = () => (dispatch) => {
  dispatch({ type: types.TOGGLE_MODAL_DELETE_CLASS_STUDENTS, payload: { toggle: false } });
};

export const openModalTransferStudents = (classId) => (dispatch) => {
  dispatch({
    type: types.TOGGLE_MODAL_TRANSFER_STUDENTS,
    payload: { toggle: true, modalSourceClassId: classId },
  });
};

export const closeModalTransferStudents = () => (dispatch) => {
  dispatch({ type: types.TOGGLE_MODAL_TRANSFER_STUDENTS, payload: { toggle: false } });
};
