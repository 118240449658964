import * as types from "../types";

const INITIAL_STATE = {
  reportCards: [],
  isLoadingReportCards: false,
  reportCardsLoaded: false,

  isAddingReportCard: false,
  reportCardAdded: false,

  isSendingMultipleCards: false,

  isDeletingReportCard: false,
  reportCardDeleted: false,

  isFirstInit: false,

  singleReportCard: null,
  isLoadingSingleReportCard: false,
  singleReportCardLoaded: false,

  isSendingSingleReportCard: false,
  singleReportCardSent: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_CURRENT_TRIMESTER_REPORT_CARDS:
      return {
        ...state,
        reportCards: action.payload,
        isLoadingReportCards: false,
        reportCardsLoaded: true,
        isFirstInit: true,
      };

    case types.GET_ALL_REPORT_CARDS_REQUEST:
      return {
        ...state,
        isLoadingReportCards: true,
      };

    case types.GET_ALL_REPORT_CARDS_SUCCESS:
      return {
        ...state,
        reportCards: action.payload,
        isLoadingReportCards: false,
        reportCardsLoaded: true,
        isFirstInit: false,
      };

    case types.GET_ALL_REPORT_CARDS_FAILURE:
      return {
        ...state,
        isLoadingReportCards: false,
      };

    case types.ADD_REPORT_CARD_REQUEST:
      return {
        ...state,
        isAddingReportCard: true,
      };

    case types.ADD_REPORT_CARD_SUCCESS:
      return {
        ...state,
        isAddingReportCard: false,
        reportCardAdded: true,
      };

    case types.ADD_REPORT_CARD_FAILURE:
      return {
        ...state,
        isAddingReportCard: false,
      };

    case types.DELETE_REPORT_CARD_REQUEST:
      return {
        ...state,
        isDeletingReportCard: true,
      };

    case types.DELETE_REPORT_CARD_SUCCESS:
      return {
        ...state,
        isDeletingReportCard: false,
        reportCardDeleted: true,
      };

    case types.DELETE_REPORT_CARD_FAILURE:
      return {
        ...state,
        isDeletingReportCard: false,
      };

    case types.RESET_REPORT_CARD_DELETED:
      return {
        ...state,
        reportCardDeleted: false,
      };

    case types.SEND_REPORT_CARD_REQUEST:
      return {
        ...state,
        isSendingSingleReportCard: true,
      };

    case types.SEND_REPORT_CARD_SUCCESS:
      return {
        ...state,
        isSendingSingleReportCard: false,
        singleReportCardSent: true,
      };

    case types.SEND_REPORT_CARD_FAILURE:
      return {
        ...state,
        isSendingSingleReportCard: false,
      };

    case types.SEND_MULTIPLE_REPORT_CARDS_REQUEST:
      return {
        ...state,
        isSendingMultipleCards: true,
      };

    case types.SEND_MULTIPLE_REPORT_CARDS_SUCCESS:
      return {
        ...state,
        isSendingMultipleCards: false,
      };

    case types.SEND_MULTIPLE_REPORT_CARDS_FAILURE:
      return {
        ...state,
        isSendingMultipleCards: false,
      };

    case types.GET_REPORT_CARD_REQUEST:
      return {
        ...state,
        isLoadingSingleReportCard: true,
      };

    case types.GET_REPORT_CARD_SUCCESS:
      return {
        ...state,
        isLoadingSingleReportCard: false,
        singleReportCard: action.payload,
        singleReportCardLoaded: true,
      };

    case types.GET_REPORT_CARD_FAILURE:
      return {
        ...state,
        isLoadingSingleReportCard: false,
      };

    default:
      return state;
  }
};
