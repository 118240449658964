import * as React from "react";
import Box from "@mui/material/Box";
import MDProgress from "components/MDProgress";
import MDTypography from "components/MDTypography";

function LinearProgressWithLabel({ value, text, ...props }) {
  console.log("Value", value);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <MDProgress value={value} {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <MDTypography variant="body2" fontWeight="medium">
          {text}
        </MDTypography>
      </Box>
    </Box>
  );
}

export default LinearProgressWithLabel;
