import { StatusCodes } from "http-status-codes";

import { getUrlWithQueryParams } from "utils/queryBuilder";

import Api from "../../api/Api";
import * as types from "../types";
import { navRoutes } from "../../constants/routes";
import { showSuccessAlert } from "./alertActions";
import history from "../../router/router-history";

export const getAllReportCards =
  (filters = null) =>
  async (dispatch) => {
    let url = getUrlWithQueryParams("/reportCards", filters);

    console.log("Url", url);

    try {
      dispatch({ type: types.GET_ALL_REPORT_CARDS_REQUEST });

      const res = await Api.get(url);
      console.log("Res getAllReportCards", res.data);
      dispatch({ type: types.GET_ALL_REPORT_CARDS_SUCCESS, payload: res.data });
    } catch (e) {
      console.log("Error Handling getAllReportCards action ", e);
      dispatch({ type: types.GET_ALL_REPORT_CARDS_FAILURE });
    }
  };

export const getReportCard = (id) => async (dispatch) => {
  const url = `/reportCards/${id}`;
  try {
    dispatch({ type: types.GET_REPORT_CARD_REQUEST });

    const res = await Api.get(url);
    console.log("Res getReportCard", res.data);

    dispatch({ type: types.GET_REPORT_CARD_SUCCESS, payload: res.data });
  } catch (e) {
    console.log("Error handling getReportCard ", e);
    dispatch({ type: types.GET_REPORT_CARD_FAILURE });
  }
};

export const getCurrentTrimesterReportCards = () => async (dispatch) => {
  const url = "/reportCards/currentTrimester";
  try {
    dispatch({ type: types.GET_ALL_REPORT_CARDS_REQUEST });

    const res = await Api.get(url);
    console.log("Res getCurrentTrimesterReportCards", res.data);
    dispatch({ type: types.GET_CURRENT_TRIMESTER_REPORT_CARDS, payload: res.data });
  } catch (e) {
    console.log("Error handling getCurrentTrimesterReportCards ", e);
    dispatch({ type: types.GET_ALL_REPORT_CARDS_FAILURE });
  }
};

export const addReportCard = (data) => async (dispatch) => {
  const url = "/reportCards";
  console.log("Data", data);
  try {
    dispatch({ type: types.ADD_REPORT_CARD_REQUEST });

    const res = await Api.post(url, data);
    console.log("Res addReportCard", res.data);
    dispatch({ type: types.ADD_REPORT_CARD_SUCCESS, payload: res.data });
    dispatch(
      showSuccessAlert({
        title: "Création effectuée",
        content: "Bulletin créé avec succès.",
      })
    );
    history.push(navRoutes.REPORT_CARDS);
  } catch (e) {
    console.log("Error Handling getAllReportCards action ", e);
    dispatch({ type: types.ADD_REPORT_CARD_FAILURE });
  }
};

export const deleteReportCard = (id) => async (dispatch) => {
  const url = `/reportCards/${id}`;
  try {
    dispatch({ type: types.DELETE_REPORT_CARD_REQUEST });

    const res = await Api.delete(url);
    dispatch({ type: types.DELETE_REPORT_CARD_SUCCESS, payload: res.data });
    dispatch(
      showSuccessAlert({
        title: "Suppression effectuée",
        content: "Bulletin supprimé avec succès.",
      })
    );

    dispatch({ type: types.TOGGLE_MODAL_CONFIRM_DELETE, payload: { toggle: false } });
    dispatch({ type: types.RESET_REPORT_CARD_DELETED });

    history.push(navRoutes.REPORT_CARDS);
  } catch (e) {
    console.log("Error Handling deleteReportCard action ", e);
    dispatch({ type: types.DELETE_REPORT_CARD_FAILURE });
  }
};

export const sendReportCard = (id, customEmail, viaSms) => async (dispatch) => {
  const url = "/sendReportCard";
  console.log("Id", id);
  try {
    dispatch({ type: types.SEND_REPORT_CARD_REQUEST });
    const res = await Api.post(url, { id, customEmail, viaSms });
    console.log("Res SendReportCard", res.data);

    if (res.status === StatusCodes.OK) {
      dispatch({ type: types.SEND_REPORT_CARD_SUCCESS });
      dispatch({ type: types.TOGGLE_MODAL_CHOOSE_RECEIVER, payload: { toggle: false } });
      dispatch(
        showSuccessAlert({
          title: "Envoi effectué",
          content: "Bulletin envoyé avec succès.",
        })
      );

      history.push(navRoutes.REPORT_CARDS);
    }
  } catch (e) {
    console.log("Error handling SendReportCard Action", e);
    dispatch({ type: types.SEND_REPORT_CARD_FAILURE });
  }
};

export const sendMultipleReportCards =
  (ids = []) =>
  async (dispatch) => {
    const url = "/sendMultipleReportCards";
    try {
      dispatch({ type: types.SEND_MULTIPLE_REPORT_CARDS_REQUEST });
      const res = await Api.post(url, ids);
      console.log("Res SendReportCard", res.data);
      dispatch({ type: types.SEND_MULTIPLE_REPORT_CARDS_SUCCESS });
      dispatch({ type: types.TOGGLE_MODAL_CONFIRM_SEND_ALL, payload: { toggle: false } });
      dispatch(
        showSuccessAlert({
          title: "Envoi effectuée",
          content: "Bulletins envoyés avec succès.",
        })
      );
      history.push(navRoutes.REPORT_CARDS);
    } catch (e) {
      console.log("Error handling sendMultipleReportCards Action", e);
      dispatch({ type: types.SEND_MULTIPLE_REPORT_CARDS_FAILURE });
    }
  };
