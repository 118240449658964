/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  Icon,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDInput from "components/MDInput";
import { navRoutes } from "constants/routes";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../../components/reusable/ErrorMessage";
import * as action from "../../store/actions";

const INITIAL_VALUES = {
  schoolYearId: null,
  trimester: +localStorage.getItem("selectedTrimester"),
  studentId: null,
  url: null,
  reportCardFile: null,
};

function NewReportCard() {
  const [values, setValues] = useState({
    ...INITIAL_VALUES,
    trimester: +localStorage.getItem("selectedTrimester"),
  });

  const [selectedStudent, setSelectedStudent] = useState(null);
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [dropDownStudentBlurred, setDropDownStudentBlurred] = useState(false);
  const [fileInputBlurred, setFileInputBlurred] = useState(false);
  const [isStudentWithoutParents, setIsStudentWithoutParents] = useState(false);

  const { schoolYears, schoolYearsLoaded } = useSelector((state) => state.schoolYears);
  const { isAddingReportCard } = useSelector((state) => state.reportCards);
  const { students, studentsLoaded } = useSelector((state) => state.students);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action.getAllStudents());
    dispatch(action.getAllSchoolYears());

    return () => setValues(INITIAL_VALUES);
  }, []);

  useEffect(() => {
    if (studentsLoaded) {
      let options = [];
      students.map((student) => {
        const label = `${student.firstName} ${student.lastName} - ${student.schoolClass.className}`;
        options.push({ id: student.id, label });
      });
      setDropDownOptions(options);
    }
  }, [students, studentsLoaded]);

  useEffect(() => {
    if (schoolYearsLoaded && schoolYears.length > 0) {
      setValues({ ...values, schoolYearId: schoolYears[0].id });
    }
  }, [schoolYears, schoolYearsLoaded]);

  const handleChangeFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reportCardFile = e.target.files[0];
      console.log("ReportCardFile", reportCardFile);
      setValues({
        ...values,
        reportCardFile,
      });
    } else {
      setValues({
        ...values,
        reportCardFile: null,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangeSelectedStudent = (event, newValue) => {
    console.log("NewVa", newValue);
    setIsStudentWithoutParents(false);
    const { id } = newValue;
    const student = students.find((item) => item.id === id);
    if (!student.hasParents) {
      setIsStudentWithoutParents(true);
    }
    setSelectedStudent(newValue);
  };

  const handleAddReportCard = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("studentId", selectedStudent.id);
    formData.append("trimester", values.trimester);
    formData.append("file", values.reportCardFile);
    formData.append("schoolYearId", values.schoolYearId);

    localStorage.setItem("selectedTrimester", values.trimester);

    dispatch(action.addReportCard(formData));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <form onSubmit={handleAddReportCard}>
              <Card>
                <MDBox p={2}>
                  <MDTypography variant="h5">Création d'un nouveau bulletin</MDTypography>
                </MDBox>

                {/* School Year */}
                <FormControl sx={{ mx: 2 }}>
                  <InputLabel id="schoolYearSelectLabel">Année scolaire</InputLabel>
                  <Select
                    placeholder="Année scolaire"
                    id="schoolYearSelect"
                    labelId="schoolYearSelectLabel"
                    label="Année scolaire"
                    name="schoolYearId"
                    onChange={handleInputChange}
                    value={values.schoolYearId || ""}
                    fullWidth
                    IconComponent={() => (
                      <MDBox iconOnly pr={1}>
                        <Icon fontSize="medium" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          arrow_drop_down
                        </Icon>
                      </MDBox>
                    )}
                    style={{ paddingTop: 15, paddingBottom: 10 }}
                    variant="outlined"
                  >
                    {schoolYears &&
                      schoolYears.map((year) => (
                        <MenuItem key={year.id} value={year.id}>
                          {year.schoolYear}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                {/* Trimester */}
                <FormControl sx={{ mx: 2, mt: 2 }}>
                  <InputLabel id="trimesterLabel">Trimestre</InputLabel>
                  <Select
                    placeholder="Trimestre"
                    id="trimesterSelect"
                    labelId="trimesterLabel"
                    label="Trimestre"
                    name="trimester"
                    onChange={handleInputChange}
                    value={values.trimester || 1}
                    fullWidth
                    IconComponent={() => (
                      <MDBox iconOnly pr={1}>
                        <Icon fontSize="medium" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          arrow_drop_down
                        </Icon>
                      </MDBox>
                    )}
                    style={{ paddingTop: 15, paddingBottom: 10 }}
                    variant="outlined"
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                  </Select>
                </FormControl>

                {/* Student */}

                <MDBox pt={2} px={2}>
                  <MDBox>
                    <Autocomplete
                      disablePortal
                      id="combo-box"
                      options={dropDownOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Élève"
                          error={selectedStudent === null && dropDownStudentBlurred}
                        />
                      )}
                      value={selectedStudent || null}
                      onChange={(event, newValue) => handleChangeSelectedStudent(event, newValue)}
                      onBlur={() => setDropDownStudentBlurred(true)}
                    />
                    <ErrorMessage
                      message={
                        selectedStudent === null &&
                        dropDownStudentBlurred &&
                        "Vous devez sélectionner un élève."
                      }
                    />
                    <ErrorMessage
                      message={
                        isStudentWithoutParents && (
                          <MDTypography variant="caption" color="error">
                            Aucun parent n'est affecté à cet élève.
                            <Link
                              to={navRoutes.NEW_PARENT}
                              style={{
                                textDecoration: "none",
                                color: "#F44335",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              Veuillez lui en créer.
                            </Link>
                          </MDTypography>
                        )
                      }
                    />
                  </MDBox>
                </MDBox>

                {/* Report Card File */}
                <MDBox pt={2} px={2}>
                  <MDBox>
                    <MDInput
                      label="Bulletin PDF"
                      type="file"
                      onChange={handleChangeFile}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={values.reportCardFile === null && fileInputBlurred}
                      onBlur={() => setFileInputBlurred(true)}
                    />
                    <ErrorMessage
                      message={
                        values.reportCardFile === null &&
                        fileInputBlurred &&
                        "Vous devez sélectionner un bulletin."
                      }
                    />
                  </MDBox>
                </MDBox>
                <MDBox
                  mt={4}
                  mb={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  backgroundColor="blue"
                >
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="info"
                    onClick={handleAddReportCard}
                    style={{
                      paddingLeft: "50px",
                      paddingRight: "50px",
                      paddingTop: "15px",
                      paddingBottom: "15px",
                    }}
                    disabled={
                      isStudentWithoutParents ||
                      isAddingReportCard ||
                      selectedStudent === null ||
                      values.reportCardFile === null
                    }
                  >
                    {isAddingReportCard ? <CircularProgress color="white" size={20} /> : "Créer"}
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewReportCard;
