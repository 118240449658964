/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts

// @mui icons
import Icon from "@mui/material/Icon";

import NewStudent from "layouts/new-student";
import NewParent from "layouts/new-parent";
import StudentsList from "layouts/students";
import ParentsList from "layouts/parents";
import NewReportCard from "layouts/new-report-card";
import ReportCardsList from "layouts/report-cards";
import NewNotice from "layouts/new-notice";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import SchoolClassesList from "layouts/school-classes";
import NewSchoolClass from "layouts/new-school-class";
import SchoolYearsList from "layouts/school-years";
import NewSchoolYear from "layouts/new-school-year";

import TeachersList from "layouts/teachers";
import NewTeacher from "layouts/new-teacher";
import NoticesList from "layouts/notices";
import { navRoutes } from "./constants/routes";

const routes = [
  // Students
  {
    type: "collapse",
    name: "Élèves",
    key: "students",
    icon: <Icon fontSize="small">person</Icon>,
    route: navRoutes.STUDENTS,
    component: <StudentsList />,
    hasChildren: true,
    isProtected: true,
    nestedRoutes: [
      {
        type: "collapse",
        name: "Nouvel Élève",
        key: "students/new",
        icon: <Icon fontSize="small">add</Icon>,
        route: navRoutes.NEW_STUDENT,
        component: <NewStudent />,
        isProtected: true,
      },
      {
        type: "collapse",
        name: "Liste Élèves",
        key: "students",
        icon: <Icon fontSize="small">person</Icon>,
        route: navRoutes.STUDENTS,
        component: <StudentsList />,
        isProtected: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Nouvel Élève",
    key: "students/new",
    icon: <Icon fontSize="small">add</Icon>,
    route: navRoutes.NEW_STUDENT,
    component: <NewStudent />,
    hidden: true,
    isProtected: true,
  },

  // Parents
  {
    type: "collapse",
    name: "Parents",
    key: "parents",
    icon: <Icon fontSize="small">escalator_warning</Icon>,
    route: navRoutes.PARENTS,
    component: <ParentsList />,
    hasChildren: true,
    isProtected: true,
    nestedRoutes: [
      {
        type: "collapse",
        name: "Nouveau Parent",
        key: "parents/new",
        icon: <Icon fontSize="small">add</Icon>,
        route: navRoutes.NEW_PARENT,
        component: <NewParent />,
        isProtected: true,
      },
      {
        type: "collapse",
        name: "Liste Parents",
        key: "parents",
        icon: <Icon fontSize="small">escalator_warning</Icon>,
        route: navRoutes.PARENTS,
        component: <ParentsList />,
        isProtected: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Nouveau Parent",
    key: "parents/new",
    icon: <Icon fontSize="small">add</Icon>,
    route: navRoutes.NEW_PARENT,
    component: <NewParent />,
    hidden: true,
    isProtected: true,
  },

  // ReportCards
  {
    type: "collapse",
    name: "Bulletins",
    key: "reportCards",
    icon: <Icon fontSize="small">insert_drive_file</Icon>,
    route: navRoutes.REPORT_CARDS,
    component: <ReportCardsList />,
    hasChildren: true,
    isProtected: true,
    nestedRoutes: [
      {
        type: "collapse",
        name: "Nouveau Bulletin",
        key: "reportCards/new",
        icon: <Icon fontSize="small">add</Icon>,
        route: navRoutes.NEW_REPORT_CARD,
        component: <NewReportCard />,
        isProtected: true,
      },
      {
        type: "collapse",
        name: "Liste Bulletins",
        key: "reportCards",
        icon: <Icon fontSize="small">insert_drive_file</Icon>,
        route: navRoutes.REPORT_CARDS,
        component: <ReportCardsList />,
        isProtected: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Nouveau Bulletin",
    key: "reportCards/new",
    icon: <Icon fontSize="small">add</Icon>,
    route: navRoutes.NEW_REPORT_CARD,
    component: <NewReportCard />,
    hidden: true,
    isProtected: true,
  },

  // Classes
  {
    type: "collapse",
    name: "Classes",
    key: "classes",
    icon: <Icon fontSize="small">school</Icon>,
    route: navRoutes.CLASSES,
    component: <SchoolClassesList />,
    hasChildren: true,
    isProtected: true,
    nestedRoutes: [
      {
        type: "collapse",
        name: "Nouvelle Classe",
        key: "classes/new",
        icon: <Icon fontSize="small">add</Icon>,
        route: navRoutes.NEW_CLASS,
        component: <NewSchoolClass />,
        isProtected: true,
      },
      {
        type: "collapse",
        name: "Liste Classes",
        key: "classes",
        icon: <Icon fontSize="small">school</Icon>,
        route: navRoutes.CLASSES,
        component: <SchoolClassesList />,
        isProtected: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Nouvelle Classe",
    key: "classes/new",
    icon: <Icon fontSize="small">add</Icon>,
    route: navRoutes.NEW_CLASS,
    component: <NewSchoolClass />,
    hidden: true,
    isProtected: true,
  },

  // SchoolYears
  {
    type: "collapse",
    name: "Années Scolaires",
    key: "schoolYears",
    icon: <Icon fontSize="small">calendar_today</Icon>,
    route: navRoutes.SCHOOL_YEARS,
    component: <SchoolYearsList />,
    hasChildren: true,
    isProtected: true,
    nestedRoutes: [
      {
        type: "collapse",
        name: "Nouvelle Année",
        key: "schoolYears/new",
        icon: <Icon fontSize="small">add</Icon>,
        route: navRoutes.NEW_SCHOOL_YEAR,
        component: <NewSchoolYear />,
        isProtected: true,
      },
      {
        type: "collapse",
        name: "Liste Années Scolaires",
        key: "schoolYears",
        icon: <Icon fontSize="small">calendar</Icon>,
        route: navRoutes.SCHOOL_YEARS,
        component: <SchoolYearsList />,
        isProtected: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Nouvelle Année",
    key: "schoolYears/new",
    icon: <Icon fontSize="small">add</Icon>,
    route: navRoutes.NEW_SCHOOL_YEAR,
    component: <NewSchoolYear />,
    hidden: true,
    isProtected: true,
  },

  // Teachers
  {
    type: "collapse",
    name: "Enseignants",
    key: "teachers",
    icon: <Icon fontSize="small">hail</Icon>,
    route: navRoutes.TEACHERS,
    component: <TeachersList />,
    hasChildren: true,
    isProtected: true,
    nestedRoutes: [
      {
        type: "collapse",
        name: "Nouvel Enseignant",
        key: "teachers/new",
        icon: <Icon fontSize="small">add</Icon>,
        route: navRoutes.NEW_TEACHER,
        component: <NewTeacher />,
        isProtected: true,
      },
      {
        type: "collapse",
        name: "Liste Enseignants",
        key: "teachers",
        icon: <Icon fontSize="small">hail</Icon>,
        route: navRoutes.TEACHERS,
        component: <TeachersList />,
        isProtected: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Nouvel Enseignant",
    key: "teachers/new",
    icon: <Icon fontSize="small">add</Icon>,
    route: navRoutes.NEW_TEACHER,
    component: <NewTeacher />,
    hidden: true,
    isProtected: true,
  },

  // Notes
  {
    type: "collapse",
    name: "Annonces",
    key: "notices",
    icon: <Icon fontSize="small">info</Icon>,
    route: navRoutes.NOTICES,
    component: <NoticesList />,
    hasChildren: true,
    isProtected: true,
    nestedRoutes: [
      {
        type: "collapseSingle",
        name: "Nouvelle Annonce",
        key: "notices/new",
        icon: <Icon fontSize="small">info</Icon>,
        route: navRoutes.NEW_NOTICE,
        component: <NewNotice />,
        isProtected: true,
      },
      {
        type: "collapse",
        name: "Liste Annonces",
        key: "notices",
        icon: <Icon fontSize="small">escalator_warning</Icon>,
        route: navRoutes.NOTICES,
        component: <NoticesList />,
        isProtected: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Nouvelle Annonce",
    key: "notices/new",
    icon: <Icon fontSize="small">add</Icon>,
    route: navRoutes.NEW_NOTICE,
    component: <NewNotice />,
    hidden: true,
    isProtected: true,
  },

  // // Register
  // {
  //   type: "collapse",
  //   name: "Inscription",
  //   key: "register",
  //   route: navRoutes.REGISTER,
  //   component: <SignUp />,
  //   hidden: true,
  // },

  // Login
  {
    type: "collapse",
    name: "Connexion",
    key: "login",
    route: navRoutes.LOGIN,
    component: <SignIn />,
    hidden: true,
  },
];

export function getRouteElement(route) {
  return routes.find((item) => item.route === route);
}

export default routes;
