import { CircularProgress, Divider, Grid, Icon, Modal } from "@mui/material";
import { Box } from "@mui/system";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classStudents from "../data/classStudents";
import * as action from "../../../store/actions";
import ModalConfirmDeleteClassStudents from "./ModalConfirmDeleteClassStudents";

const ModalDeleteStudents = ({ open }) => {
  const [isCustomDelete, setIsCustomDelete] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);

  const { toggleModalDeleteClassStudents, modalClassId } = useSelector((state) => state.modals);
  const { isDeletingClassStudents, classes } = useSelector((state) => state.schoolClasses);

  const dispatch = useDispatch();

  const { columns, rows } = classStudents(
    selectedClass?.students || [],
    (ids) => onChangeSelection(ids),
    toggleModalDeleteClassStudents
  );

  useEffect(() => {
    const schoolClass = classes && classes.find((item) => item.id === modalClassId);
    if (schoolClass) {
      setSelectedClass({
        ...schoolClass,
      });
    }
  }, [modalClassId]);

  useEffect(() => {
    if (!toggleModalDeleteClassStudents) {
      setIsCustomDelete(false);
      setSelectedClass(null);
      setShowModalConfirmDelete(false);
    }
  }, [toggleModalDeleteClassStudents]);

  const handleClose = () => {
    dispatch(action.closeModalDeleteClassStudents());
  };

  const onChangeSelection = (ids) => {
    console.log("onChangeSelection", ids);
    setSelectedStudents(ids);
  };

  const handleConfirmDelete = () => {
    const studentsToDelete = {
      schoolClassId: selectedClass.id,
      isCustomDelete,
      students: isCustomDelete ? selectedStudents : [],
    };

    console.log("StudentsToDelete Object", studentsToDelete);
    dispatch(action.closeModalDeleteClassStudents());
    dispatch(action.deleteClassStudents(studentsToDelete));
  };

  return (
    <Modal
      open={toggleModalDeleteClassStudents}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box width={600} style={{ background: "white", outline: "none", borderRadius: 8 }}>
        <MDBox mt={3} mb={2}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2} display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="h5">Suppression élèves</MDTypography>
              <MDButton iconOnly onClick={handleClose}>
                <Icon fontSize="large">close</Icon>
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
        <MDBox px={4}>
          <MDBox display="flex" flexDirection="row" alignItems="center">
            <input
              type="radio"
              name="isCustomDelete"
              onChange={() => setIsCustomDelete(false)}
              checked={isCustomDelete === false}
              style={{ height: 15, width: 15, marginRight: 10 }}
            />
            <MDTypography
              fontSize="medium"
              fontWeight="bold"
              pl={1}
              onClick={() => setIsCustomDelete(false)}
            >
              Supprimer tous les élèves
            </MDTypography>
          </MDBox>

          <MDBox display="flex" flexDirection="row" alignItems="center" mt={2}>
            <input
              type="radio"
              name="isCustomDelete"
              onChange={() => setIsCustomDelete(true)}
              checked={isCustomDelete}
              style={{ height: 15, width: 15, marginRight: 10 }}
            />
            <MDTypography
              fontSize="medium"
              fontWeight="bold"
              pl={1}
              onClick={() => setIsCustomDelete(true)}
            >
              Suppression personnalisée
            </MDTypography>
          </MDBox>

          {isCustomDelete && (
            <MDBox py={2} sx={{ maxHeight: 200, overflowY: "scroll" }}>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={{ defaultValue: 50 }}
                showTotalEntries={false}
                noEndBorder
                checkboxSelection
              />
            </MDBox>
          )}

          <Divider />
          <MDBox display="flex" justifyContent="center" alignItems="center" py={2}>
            <MDButton
              color="error"
              onClick={() => setShowModalConfirmDelete(true)}
              disabled={
                isDeletingClassStudents || (isCustomDelete && selectedStudents.length === 0)
              }
            >
              {isDeletingClassStudents ? <CircularProgress color="white" size={20} /> : "Supprimer"}
            </MDButton>
          </MDBox>
        </MDBox>
        <ModalConfirmDeleteClassStudents
          open={showModalConfirmDelete}
          onConfirm={handleConfirmDelete}
          onClose={() => setShowModalConfirmDelete(false)}
        />
      </Box>
    </Modal>
  );
};

export default ModalDeleteStudents;
