import React from "react";
import { Divider, Modal } from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const ModalConfirmSendNotice = ({ open = false, message, onClose, onConfirm }) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
  >
    <MDBox
      width={600}
      py={2}
      px={2}
      style={{ background: "white", outline: "none", borderRadius: 8 }}
    >
      <MDTypography>Confirmation d'envoi d'une annonce</MDTypography>
      <Divider />
      <MDTypography fontSize="medium">{message}</MDTypography>
      <Divider />
      <MDBox display="flex" justifyContent="center" alignItems="center" mt={3}>
        <MDBox mr={1}>
          <MDButton color="secondary" onClick={onClose}>
            Annuler
          </MDButton>
        </MDBox>
        <MDBox ml={1}>
          <MDButton color="info" onClick={onConfirm}>
            Confirmer
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  </Modal>
);

export default ModalConfirmSendNotice;
