/* eslint-disable react/prop-types */

import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

// Images
// import logoXD from "assets/images/small-logos/logo-xd.svg";
// import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
// import logoSlack from "assets/images/small-logos/logo-slack.svg";
// import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
// import logoJira from "assets/images/small-logos/logo-jira.svg";
// import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";
import Icon from "@mui/material/Icon";
import { Button, Checkbox, Tooltip } from "@mui/material";

import Swal from "sweetalert2";
import * as action from "../../../store/actions";
import * as types from "../../../store/types";

export default function allReportCardsData() {
  const { reportCards } = useSelector((state) => state.reportCards);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(action.getCurrentTrimesterReportCards());
  }, []);

  const Student = ({ name }) => (
    <MDBox display="inline" lineHeight={1}>
      {/* <MDAvatar src={image} name={name} size="sm" /> */}
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        {/* <MDTypography variant="caption">{email}</MDTypography> */}
      </MDBox>
    </MDBox>
  );

  const ClassName = ({ className }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {className}
      </MDTypography>
    </MDBox>
  );

  const SchoolYear = ({ year }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {year}
      </MDTypography>
    </MDBox>
  );

  const Trimester = ({ trimester }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {trimester}
      </MDTypography>
    </MDBox>
  );

  const Parents = ({ parents = [] }) => (
    <MDBox textAlign="left">
      {parents.map((parent) => (
        <MDTypography
          key={parent.id}
          component="a"
          href="#"
          display="block"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {parent.fullName}
        </MDTypography>
      ))}
    </MDBox>
  );

  const ReportCardFile = ({ url }) => (
    <MDBox textAlign="left">
      <MDButton iconOnly component="a" target="_blank" href={url}>
        <Icon fontSize="medium">file_open</Icon>
      </MDButton>
    </MDBox>
  );

  const ViaEmail = ({ viaEmail }) =>
    viaEmail && (
      <MDBox lineHeight={1} textAlign="center">
        <Icon color="success">check</Icon>
      </MDBox>
    );

  const ViaSMS = ({ viaSms }) =>
    viaSms && (
      <MDBox lineHeight={1} textAlign="center">
        <Icon color="success">check</Icon>
      </MDBox>
    );

  const handleOpenModalSendSingleReportCard = (id) => {
    console.log("Id", id);
    dispatch({
      type: types.TOGGLE_MODAL_CHOOSE_RECEIVER,
      payload: { toggle: true, id },
    });
  };

  const handleOpenModalConfirmDeleteStudent = (id) => {
    dispatch({ type: types.TOGGLE_MODAL_CONFIRM_DELETE, payload: { toggle: true, id } });
  };

  const getReportCardsRow = () => {
    let rows = [];
    reportCards.map((card) => {
      console.log("Card", card);
      rows.push({
        // selectAll: <Checkbox defaultChecked={false} size="medium" />,
        student: <Student name={`${card.student.firstName} ${card.student.lastName}`} />,
        className: <ClassName className={card.student.schoolClass.className} />,
        schoolYear: <SchoolYear year={card.schoolYear.schoolYear} />,
        trimester: <Trimester trimester={card.trimester} />,
        parents: <Parents parents={card.parents} />,
        reportCard: <ReportCardFile url={card.url} />,
        viaEmail: <ViaEmail viaEmail={card.sentByEmail} />,
        viaSms: <ViaSMS viaSms={card.sentBySms} />,
        action: (
          <MDBox>
            <Tooltip title="Envoyer au parent">
              <MDButton iconOnly onClick={() => handleOpenModalSendSingleReportCard(card.id)}>
                <Icon fontSize="small" color="info">
                  send
                </Icon>
              </MDButton>
            </Tooltip>
            <MDButton iconOnly onClick={() => handleOpenModalConfirmDeleteStudent(card.id)}>
              <Icon fontSize="small" color="error">
                delete
              </Icon>
            </MDButton>
          </MDBox>
          // <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          //   Edit
          // </MDTypography>
        ),
      });
    });

    return rows;
  };

  return {
    columns: [
      // {
      //   Header: <Checkbox size="medium" />,
      //   accessor: "selectAll",
      //   width: "5%",
      //   align: "left",
      // },
      { Header: "Élève", accessor: "student", width: "20%", align: "left" },
      { Header: "Classe", accessor: "className", align: "left" },
      { Header: "Année", accessor: "schoolYear", align: "left" },
      { Header: "Trimestre", accessor: "trimester", align: "center" },
      { Header: "Parents", accessor: "parents", width: "20%", align: "left" },
      { Header: "Bulletin", accessor: "reportCard", align: "center" },
      { Header: "Email", accessor: "viaEmail", align: "center" },
      { Header: "SMS", accessor: "viaSms", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: getReportCardsRow(),
  };
}
