import * as types from "../types";

const showBaseAlert = (alert) => async (dispatch) => {
  dispatch({ type: types.SHOW_ALERT, payload: alert });
  setTimeout(() => {
    dispatch({ type: types.HIDE_ALERT });
  }, 3000);
};

export const hideAlert = () => async (dispatch) => {
  dispatch({ type: types.HIDE_ALERT });
};

export const showSuccessAlert =
  ({ title, content }) =>
  async (dispatch) => {
    const alert = {
      color: "success",
      icon: "check",
      title,
      content,
    };

    dispatch(showBaseAlert(alert));
  };

export const showErrorAlert =
  ({ title, content }) =>
  async (dispatch) => {
    const alert = {
      color: "error",
      icon: "warning",
      title,
      content,
    };

    dispatch(showBaseAlert(alert));
  };

export const showWarningAlert =
  ({ title, content }) =>
  async (dispatch) => {
    const alert = {
      color: "warning",
      icon: "star",
      title,
      content,
    };

    dispatch(showBaseAlert(alert));
  };

export const showInfoAlert =
  ({ title, content }) =>
  async (dispatch) => {
    const alert = {
      color: "info",
      icon: "notifications",
      title,
      content,
    };

    dispatch(showBaseAlert(alert));
  };
