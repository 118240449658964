/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";
import { Provider } from "react-redux";
import { AuthProvider } from "hooks/useAuth";
import { configuredStore } from "./store/configStore";
import CustomRouter from "./router/custom-router";
import customHistory from "./router/router-history";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <CustomRouter history={customHistory}>
    <MaterialUIControllerProvider>
      <Provider store={configuredStore}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Provider>
    </MaterialUIControllerProvider>
  </CustomRouter>,
  document.getElementById("root")
);
