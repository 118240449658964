import { StatusCodes } from "http-status-codes";
import * as types from "../types";
import Api from "../../api/Api";
import { showSuccessAlert } from "./alertActions";
import history from "../../router/router-history";
import { navRoutes } from "../../constants/routes";
import { getUrlWithQueryParams } from "../../utils/queryBuilder";

export const addTeacher = (data) => async (dispatch) => {
  const url = "teachers";
  try {
    dispatch({ type: types.ADD_TEACHER_REQUEST });
    const response = await Api.post(url, data);
    console.log("Res addTeacher", response.data);
    dispatch({ type: types.ADD_TEACHER_SUCCESS, payload: response.data });
    dispatch(
      showSuccessAlert({
        title: "Création effectuée",
        content: "Enseignant créé avec succès.",
      })
    );
    history.push(navRoutes.TEACHERS);
  } catch (e) {
    console.error("Error handling AddTeacher Action ", e);
    dispatch({ type: types.ADD_TEACHER_FAILURE });
    const { status } = e.response;
    if (status === StatusCodes.CONFLICT) {
      dispatch({ type: types.ADD_OR_UPDATE_TEACHER_FAILURE_CONFLICT });
    }
  }
};

export const getAllTeachers =
  ({ classId, searchQuery } = {}) =>
  async (dispatch) => {
    console.log("classObject", { classId, searchQuery });
    // console.log("searchQuery", searchQuery);
    const url = getUrlWithQueryParams("/teachers", { classId, searchQuery });
    console.log("Url", url);
    try {
      dispatch({ type: types.GET_ALL_TEACHERS_REQUEST });
      const response = await Api.get(url);
      console.log("Res getAllTeachers", response.data);
      dispatch({ type: types.GET_ALL_TEACHERS_SUCCESS, payload: response.data });
    } catch (e) {
      console.error("Error Handling GetAllTeachers Action ", e);
      dispatch({ type: types.GET_ALL_TEACHERS_FAILURE });
    }
  };

export const getTeacher = (id) => async (dispatch) => {
  const url = `/teachers/${id}`;
  try {
    dispatch({ type: types.GET_TEACHER_REQUEST });
    const response = await Api.get(url, { id });
    console.log("Res getTeacher", response.data);
    dispatch({ type: types.GET_TEACHER_SUCCESS, payload: response.data });
  } catch (e) {
    console.error("Error Handling getTeacher Action ", e);
    dispatch({ type: types.GET_TEACHER_FAILURE });
  }
};

export const updateTeacher = (newTeacher) => async (dispatch) => {
  const url = "teachers/edit";
  try {
    dispatch({ type: types.UPDATE_TEACHER_REQUEST });
    const res = await Api.post(url, newTeacher);
    console.log("Res updateTeacher", res.data);
    dispatch({ type: types.UPDATE_TEACHER_SUCCESS });
    dispatch(
      showSuccessAlert({
        title: "Modification effectuée",
        content: "Enseignant modifié avec succès.",
      })
    );
    dispatch({ type: types.TOGGLE_MODAL_EDIT_TEACHER, payload: { toggle: false } });
    dispatch(getAllTeachers());
    history.push(navRoutes.TEACHERS);
  } catch (e) {
    console.log("Error handling updateTeacher action", e);
    dispatch({ type: types.UPDATE_TEACHER_FAILURE });
    const { status } = e.response;
    if (status === StatusCodes.CONFLICT) {
      dispatch({ type: types.ADD_OR_UPDATE_TEACHER_FAILURE_CONFLICT });
    }
  }
};

export const deleteTeacher = (id) => async (dispatch) => {
  const url = `/teachers/${id}`;
  try {
    dispatch({ type: types.DELETE_TEACHER_REQUEST });

    const response = await Api.delete(url);
    console.log("Res deleteTeacher ", response.data);
    dispatch({ type: types.DELETE_TEACHER_SUCCESS, payload: response.data });
    dispatch(
      showSuccessAlert({
        title: "Suppression effectuée",
        content: "Enseignant supprimé avec succès.",
      })
    );
    dispatch({ type: types.TOGGLE_MODAL_CONFIRM_DELETE, payload: { toggle: false } });
    dispatch({ type: types.RESET_TEACHER_DELETED });
    dispatch(getAllTeachers());
    history.push(navRoutes.TEACHERS);
  } catch (e) {
    console.log("Error handling deleteTeacher action ", e);
    dispatch({ type: types.DELETE_TEACHER_FAILURE });
  }
};

export const resetTeacherExistsFlag = () => (dispatch) => {
  dispatch({ type: types.RESET_TEACHER_EXISTS });
};
