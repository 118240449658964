/* eslint-disable react/prop-types */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Icon from "@mui/material/Icon";
import moment from "moment";

import * as action from "../../../store/actions";
import * as types from "../../../store/types";

export default function allNoticesData() {
  const { notices } = useSelector((state) => state.notices);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action.getAllNotices());
  }, []);

  const handleOpenModalNoticeDetails = (id) => {
    dispatch(action.openModalNoticeDetails(id));
  };

  const Title = ({ name = "" }) => (
    <MDBox display="inline" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const DateTime = ({ date }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {date}
      </MDTypography>
    </MDBox>
  );

  const Type = ({ name }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {name}
      </MDTypography>
    </MDBox>
  );

  const ViaEmail = ({ viaEmail }) =>
    viaEmail && (
      <MDBox lineHeight={1} textAlign="center">
        <Icon color="success">check</Icon>
      </MDBox>
    );

  const ViaSMS = ({ viaSms }) =>
    viaSms && (
      <MDBox lineHeight={1} textAlign="center">
        <Icon color="success">check</Icon>
      </MDBox>
    );

  const getNoticesRows = () => {
    let rows = [];
    notices.map((notice) => {
      rows.push({
        title: <Title name={notice.subject} />,
        dateTime: <DateTime date={moment(notice.createdAt).format("DD-MM-YYYY HH:mm")} />,
        type: <Type name={notice.type?.label} />,
        email: <ViaEmail viaEmail={notice.viaEmail} />,
        sms: <ViaSMS viaSms={notice.viaSms} />,
        action: (
          <MDBox>
            <MDButton iconOnly onClick={() => handleOpenModalNoticeDetails(notice.id)}>
              <Icon fontSize="small" color="info">
                visibility
              </Icon>
            </MDButton>
          </MDBox>
        ),
      });
    });

    return rows;
  };

  return {
    columns: [
      { Header: "Sujet", accessor: "title", width: "30%", align: "left" },
      { Header: "Date", accessor: "dateTime", align: "left" },
      { Header: "Type", accessor: "type", align: "left" },
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "SMS", accessor: "sms", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: getNoticesRows(),
  };
}
