/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { CircularProgress } from "@mui/material";
import ErrorMessage from "components/reusable/ErrorMessage";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { navRoutes } from "constants/routes";

import * as action from "../../../store/actions";
import { useAuth } from "../../../hooks/useAuth";

const initialValues = {
  email: "",
  password: "",
};

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const { isLogging, invalidCredentials, isConnected } = useSelector((state) => state.auth);

  const { state } = useLocation();
  const navigate = useNavigate();
  const { signin, signout, isAuthed } = useAuth();

  let from = state?.from?.pathname || "/";

  const isUnauthorized = state?.isUnauthorized;

  useEffect(() => {
    if (isAuthed) {
      navigate(from, { replace: true });
    }
  }, []);

  /** If the Token is expired or the user is unauthorized */
  useEffect(() => {
    if (isUnauthorized === true) {
      signout();
    }
  }, [isUnauthorized]);

  const handleLogin = (values) => {
    signin(values);
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Connexion
          </MDTypography>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          >
            <Grid item xs={2}>
              {/* <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography> */}
            </Grid>
            {/* <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <IsIcon color="inherit" />
              </MDTypography>
            </Grid> */}
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik initialValues={initialValues} onSubmit={handleLogin}>
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      name="email"
                      type="email"
                      label="Email"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.email}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      name="password"
                      type="password"
                      label="Password"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.password}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox display="flex" alignItems="center" ml={-1}>
                    {/* <Switch checked={rememberMe} onChange={handleSetRememberMe} /> */}
                    {/* <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MDTypography> */}
                  </MDBox>
                  {invalidCredentials && (
                    <MDBox display="flex" justifyContent="center" alignItems="center">
                      <ErrorMessage message="Email ou mot de passe incorrect" />
                    </MDBox>
                  )}
                  <MDBox mt={3} mb={1}>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="info"
                      fullWidth
                      disabled={isLogging}
                    >
                      {isLogging ? <CircularProgress size={20} color="white" /> : "Se connecter"}
                    </MDButton>
                  </MDBox>
                  {/* <MDBox mt={3} mb={1} textAlign="center">
                    <MDTypography variant="button" color="text">
                      Don&apos;t have an account?{" "}
                      <MDTypography
                        component={Link}
                        to="/authentication/sign-up"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign up
                      </MDTypography>
                    </MDTypography>
                  </MDBox> */}
                </MDBox>
              </form>
            )}
          </Formik>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
