import { hasValue } from "./helpers";

export const getUrlWithQueryParams = (url, object) => {
  if (hasValue(object)) {
    url += "?";

    Object.keys(object).map((key, index) => {
      if (object[key] !== null && object[key] !== undefined && object[key] !== "") {
        url += `${key}=${object[key]}`;
        if (index + 1 < Object.keys(object).length) {
          url += "&";
        }
      }
    });
  }

  return url;
};
