import MDSnackbar from "components/MDSnackbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as action from "./store/actions";

const LocalNotifications = () => {
  const { showAlert, icon, color, title, content } = useSelector((state) => state.alerts);

  const dispatch = useDispatch();

  return (
    <MDSnackbar
      color={color}
      icon={icon}
      title={title}
      content={content}
      open={showAlert}
      onClose={() => dispatch(action.hideAlert())}
      close={() => dispatch(action.hideAlert())}
      bgWhite
    />
  );
};

export default LocalNotifications;
