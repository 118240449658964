/* eslint-disable react/prop-types */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

// Images
// import logoXD from "assets/images/small-logos/logo-xd.svg";
// import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
// import logoSlack from "assets/images/small-logos/logo-slack.svg";
// import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
// import logoJira from "assets/images/small-logos/logo-jira.svg";
// import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";
import Icon from "@mui/material/Icon";

import * as action from "../../../store/actions";
import * as types from "../../../store/types";

export default function allParentsData() {
  const { parents } = useSelector((state) => state.parents);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action.getAllParents());
  }, []);

  const Parent = ({ name, students = [] }) => (
    <MDBox display="inline" lineHeight={1}>
      {/* <MDAvatar src={image} name={name} size="sm" /> */}
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        {/* <MDTypography variant="caption">{email}</MDTypography> */}
      </MDBox>
    </MDBox>
  );

  const Email = ({ email }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {email}
      </MDTypography>
    </MDBox>
  );

  const Phone = ({ phoneNumber }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {phoneNumber}
      </MDTypography>
    </MDBox>
  );

  const Students = ({ students = [] }) => (
    <MDBox textAlign="left">
      {students.map((student, index) => (
        <MDTypography
          key={student.id}
          component="a"
          href="#"
          display="block"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {student.fullName}
        </MDTypography>
      ))}
    </MDBox>
  );

  const handleOpenModalConfirmDeleteParent = (id) => {
    console.log("Id", id);
    dispatch({
      type: types.TOGGLE_MODAL_CONFIRM_DELETE,
      payload: { toggle: true, id },
    });
  };

  const handleOpenModalEditParent = (id) => {
    console.log("Id", id);
    dispatch({ type: types.TOGGLE_MODAL_EDIT_PARENT, payload: { toggle: true, id } });
  };

  const getParentsRow = () => {
    let rows = [];
    parents.map((parent) => {
      rows.push({
        parent: <Parent name={`${parent.firstName} ${parent.lastName}`} />,
        email: <Email email={parent.email || ""} />,
        phoneNumber: <Phone phoneNumber={parent.phoneNumber} />,
        students: <Students students={parent.students} />,
        action: (
          <MDBox>
            <MDButton iconOnly onClick={() => handleOpenModalEditParent(parent.id)}>
              <Icon fontSize="small" color="info">
                edit
              </Icon>
            </MDButton>
            <MDButton iconOnly onClick={() => handleOpenModalConfirmDeleteParent(parent.id)}>
              <Icon fontSize="small" color="error">
                delete
              </Icon>
            </MDButton>
          </MDBox>
        ),
      });
    });

    return rows;
  };

  return {
    columns: [
      { Header: "Nom complet", accessor: "parent", width: "30%", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Téléphone", accessor: "phoneNumber", align: "left" },
      { Header: "Élèves", accessor: "students", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: getParentsRow(),
  };
}
