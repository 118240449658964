/* eslint-disable react/prop-types */

import React from "react";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

import Icon from "@mui/material/Icon";

import * as action from "../../../store/actions";
import * as types from "../../../store/types";

export default function allClassesData() {
  const { classes } = useSelector((state) => state.schoolClasses);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(action.getAllClasses());
  }, []);

  const ClassName = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image} name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        {/* <MDTypography variant="caption">{email}</MDTypography> */}
      </MDBox>
    </MDBox>
  );

  const StudentsNumber = ({ number }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {number}
      </MDTypography>
      {/* <MDTypography variant="caption">{description}</MDTypography> */}
    </MDBox>
  );

  const handleOpenModalEditClass = (id) => {
    console.log("Id", id);
  };

  const handleOpenModalConfirmDeleteClass = (id) => {
    console.log("Id", id);
    dispatch({ type: types.TOGGLE_MODAL_CONFIRM_DELETE, payload: { toggle: true, id } });
  };

  const handleOpenModalDeleteClassStudents = (schoolClassId) => {
    dispatch(action.openModalDeleteClassStudents(schoolClassId));
  };

  const handleOpenModalTransferStudents = (schoolClassId) => {
    dispatch(action.openModalTransferStudents(schoolClassId));
  };

  const getClassesRows = () => {
    let rows = [];
    classes.map((schoolClass) => {
      console.log("SchoolClass", schoolClass);
      rows.push({
        class: <ClassName name={schoolClass.className} />,
        studentsNumber: <StudentsNumber number={schoolClass.studentsNumber} />,
        action: (
          <MDBox>
            {/* <MDButton iconOnly onClick={() => handleOpenModalEditClass(schoolClass.id)}>
              <Icon fontSize="small" color="info">
                edit
              </Icon>
            </MDButton> */}
            <Tooltip title="Transférer des élèves">
              <MDButton iconOnly onClick={() => handleOpenModalTransferStudents(schoolClass.id)}>
                <Icon fontSize="large" color="info">
                  double_arrow
                </Icon>
              </MDButton>
            </Tooltip>

            <Tooltip title="Supprimer des élèves">
              <MDButton iconOnly onClick={() => handleOpenModalDeleteClassStudents(schoolClass.id)}>
                <Icon fontSize="small" color="error">
                  group_remove
                </Icon>
              </MDButton>
            </Tooltip>

            <Tooltip title="Supprimer la classe">
              <MDButton iconOnly onClick={() => handleOpenModalConfirmDeleteClass(schoolClass.id)}>
                <Icon fontSize="small" color="error">
                  delete
                </Icon>
              </MDButton>
            </Tooltip>
          </MDBox>
        ),
      });
    });

    return rows;
  };

  return {
    columns: [
      { Header: "Classe", accessor: "class", width: "45%", align: "left" },
      { Header: "Nombre d'élèves", accessor: "studentsNumber", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: getClassesRows(),
  };
}
