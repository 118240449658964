import Api from "../../api/Api";
import * as types from "../types";
import { navRoutes } from "../../constants/routes";
import { showSuccessAlert } from "./alertActions";
import history from "../../router/router-history";
import { getUrlWithQueryParams } from "../../utils/queryBuilder";

export const getAllStudents =
  ({ classId, schoolYearId, searchQuery } = {}) =>
  async (dispatch) => {
    const url = getUrlWithQueryParams("/students", { classId, schoolYearId, searchQuery });
    try {
      dispatch({ type: types.GET_ALL_STUDENTS_REQUEST });

      const response = await Api.get(url);
      console.log("Res GetAllStudents ", response.data);
      dispatch({ type: types.GET_ALL_STUDENTS_SUCCESS, payload: response.data });
    } catch (e) {
      console.log("Error handling getAllStudents action ", e);
      dispatch({ type: types.GET_ALL_STUDENTS_FAILURE });
    }
  };

export const getStudent = (id) => async (dispatch) => {
  const url = `/students/${id}`;
  try {
    dispatch({ type: types.GET_STUDENT_REQUEST });

    const response = await Api.get(url);
    console.log("Res GetStudent ", response.data);
    dispatch({ type: types.GET_STUDENT_SUCCESS, payload: response.data });
  } catch (e) {
    console.log("Error handling GetStudent action ", e);
    dispatch({ type: types.GET_STUDENT_FAILURE });
  }
};

export const addStudent = (data) => async (dispatch) => {
  const url = "/students";
  try {
    dispatch({ type: types.ADD_STUDENT_REQUEST });
    const response = await Api.post(url, data);

    console.log("Res GetAllStudents ", response.data);
    dispatch({ type: types.ADD_STUDENT_SUCCESS, payload: response.data });
    dispatch(
      showSuccessAlert({
        title: "Création effectuée",
        content: "Élève créé avec succès.",
      })
    );

    history.push(navRoutes.STUDENTS);
  } catch (e) {
    console.log("Error handling addStudent action ", e);
    dispatch({ type: types.ADD_STUDENT_FAILURE });
  }
};

export const updateStudent = (data) => async (dispatch) => {
  const url = `/students/edit`;
  try {
    dispatch({ type: types.UPDATE_STUDENT_REQUEST });
    const response = await Api.post(url, data);

    console.log("Res updateStudent ", response.data);
    dispatch({ type: types.UPDATE_STUDENT_SUCCESS, payload: response.data });
    dispatch(
      showSuccessAlert({
        title: "Modification effectuée",
        content: "Élève modifié avec succès.",
      })
    );
    dispatch({ type: types.TOGGLE_MODAL_EDIT_STUDENT, payload: { toggle: false } });
    dispatch(getAllStudents());
    history.push(navRoutes.STUDENTS);
  } catch (e) {
    console.log("Error handling updateStudent action ", e);
    dispatch({ type: types.UPDATE_STUDENT_FAILURE });
  }
};

export const deleteStudent = (id) => async (dispatch) => {
  const url = `/students/${id}`;
  try {
    dispatch({ type: types.DELETE_STUDENT_REQUEST });
    const response = await Api.delete(url);
    console.log("Res deleteStudent ", response.data);
    dispatch({ type: types.DELETE_STUDENT_SUCCESS, payload: response.data });
    dispatch(
      showSuccessAlert({
        title: "Suppression effectuée",
        content: "Élève supprimé avec succès.",
      })
    );
    dispatch({ type: types.TOGGLE_MODAL_CONFIRM_DELETE, payload: { toggle: false } });
    dispatch({ type: types.RESET_STUDENT_DELETED });
    dispatch(getAllStudents());
    history.push(navRoutes.STUDENTS);
  } catch (e) {
    console.log("Error handlng deleteStudent action ", e);
    dispatch({ type: types.DELETE_STUDENT_FAILURE });
  }
};
