/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useDispatch, useSelector } from "react-redux";
import MDInput from "components/MDInput";
import ErrorMessage from "../../components/reusable/ErrorMessage";
import * as action from "../../store/actions";

import { newTeacherSchema } from "./validation-schema/newTeacherSchema";

const INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  classes: [],
};

function NewTeacher() {
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [classesDropDownBlurred, setClassesDropDownBlurred] = useState(false);

  const { isAddingTeacher, teacherExists } = useSelector((state) => state.teachers);
  const { classes, classesLoaded } = useSelector((state) => state.schoolClasses);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action.getAllClasses());
  }, []);

  useEffect(() => {
    if (classesLoaded) {
      let options = [];
      classes.map((schoolClass) => {
        const label = schoolClass.className;
        options.push({ id: schoolClass.id, label });
      });
      setDropDownOptions(options);
    }
  }, [classes, classesLoaded]);

  const handleAddTeacher = (data) => {
    let classesIds = [];
    selectedClasses.map((schoolClass) => classesIds.push({ id: schoolClass.id }));

    console.log("addTeacher", { ...data, classes: [...classesIds] });
    dispatch(action.addTeacher({ ...data, classes: [...classesIds] }));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant="h5">Création d'un nouvel enseignant</MDTypography>
              </MDBox>
              <Formik
                initialValues={INITIAL_VALUES}
                validationSchema={newTeacherSchema}
                onSubmit={handleAddTeacher}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit}>
                    <MDBox pt={2} px={2}>
                      <MDBox>
                        <MDInput
                          label="Nom"
                          name="lastName"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.lastName}
                          error={
                            props.touched.lastName &&
                            props.errors.lastName &&
                            props.errors.lastName !== ""
                          }
                          fullWidth
                        />
                        <ErrorMessage message={props.touched.lastName && props.errors.lastName} />
                      </MDBox>
                    </MDBox>
                    <MDBox pt={2} px={2}>
                      <MDBox>
                        <MDInput
                          label="Prénom"
                          name="firstName"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.firstName}
                          error={
                            props.touched.firstName &&
                            props.errors.firstName &&
                            props.errors.firstName !== ""
                          }
                          fullWidth
                        />
                        <ErrorMessage message={props.touched.firstName && props.errors.firstName} />
                      </MDBox>
                    </MDBox>
                    <MDBox pt={2} px={2}>
                      <MDBox>
                        <MDInput
                          label="Email"
                          name="email"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.email}
                          error={
                            props.touched.email && props.errors.email && props.errors.email !== ""
                          }
                          fullWidth
                        />
                        <ErrorMessage message={props.touched.email && props.errors.email} />
                        <ErrorMessage
                          message={teacherExists && "Cette adresse Email est déjà utilisée."}
                        />
                      </MDBox>
                    </MDBox>
                    <MDBox pt={2} px={2}>
                      <MDBox>
                        <MDInput
                          label="Téléphone"
                          name="phoneNumber"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.phoneNumber}
                          error={
                            props.touched.phoneNumber &&
                            props.errors.phoneNumber &&
                            props.errors.phoneNumber !== ""
                          }
                          fullWidth
                        />
                        <ErrorMessage
                          message={props.touched.phoneNumber && props.errors.phoneNumber}
                        />
                      </MDBox>
                    </MDBox>
                    <MDBox pt={2} px={2}>
                      <MDBox>
                        <Autocomplete
                          disablePortal
                          multiple
                          id="combo-box"
                          onBlur={setClassesDropDownBlurred}
                          options={dropDownOptions}
                          renderInput={(params) => <TextField {...params} label="Classes" />}
                          value={selectedClasses || []}
                          onChange={(event, newValue) => {
                            setSelectedClasses([...newValue]);
                          }}
                        />
                        <ErrorMessage
                          message={
                            selectedClasses.length === 0 &&
                            classesDropDownBlurred &&
                            "Vous devez sélectionner au moins une classe."
                          }
                        />
                      </MDBox>
                    </MDBox>
                    <MDBox
                      mt={4}
                      mb={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      backgroundColor="blue"
                    >
                      <MDButton
                        type="submit"
                        variant="gradient"
                        color="info"
                        style={{
                          paddingLeft: "50px",
                          paddingRight: "50px",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                        disabled={isAddingTeacher || selectedClasses.length === 0}
                      >
                        {isAddingTeacher ? <CircularProgress color="white" size={20} /> : "Créer"}
                      </MDButton>
                    </MDBox>
                  </form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewTeacher;
