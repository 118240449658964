import React from "react";
import { CircularProgress, Divider, Modal } from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useDispatch } from "react-redux";
import * as types from "../../../store/types";

const ModalConfirmDeleteClassStudents = ({ open = false, onClose, onConfirm }) => {
  const dispatch = useDispatch();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <MDBox
        width={600}
        py={2}
        px={2}
        style={{ background: "white", outline: "none", borderRadius: 8 }}
      >
        <MDTypography>Confirmation suppression</MDTypography>
        <Divider />
        <MDTypography fontSize="medium">
          Êtes-vous certain de vouloir supprimer les élèves de cette classe ?
        </MDTypography>
        <Divider />
        <MDBox display="flex" justifyContent="center" alignItems="center" mt={3}>
          <MDBox mr={1}>
            <MDButton color="secondary" onClick={onClose}>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox ml={1}>
            <MDButton color="error" onClick={onConfirm}>
              Supprimer
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Modal>
  );
};

export default ModalConfirmDeleteClassStudents;
