/* eslint-disable react/prop-types */

import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Icon from "@mui/material/Icon";

import * as action from "../../../store/actions";
import * as types from "../../../store/types";

export default function allSchoolYearsData() {
  const { schoolYears } = useSelector((state) => state.schoolYears);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(action.getAllSchoolYears());
  }, []);

  const StartYear = ({ startYear }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {startYear}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const EndYear = ({ endYear }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {endYear}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const handleOpenModalConfirmDeleteSchoolYear = (id) => {
    console.log("Id", id);
    dispatch({ type: types.TOGGLE_MODAL_CONFIRM_DELETE, payload: { toggle: true, id } });
  };

  const getSchoolYearsRows = () => {
    let rows = [];
    schoolYears.map((year) => {
      rows.push({
        startYear: <StartYear startYear={year.startYear} />,
        endYear: <EndYear endYear={year.endYear} />,
        action: (
          <MDBox>
            <MDButton iconOnly onClick={() => handleOpenModalConfirmDeleteSchoolYear(year.id)}>
              <Icon fontSize="small" color="error">
                delete
              </Icon>
            </MDButton>
          </MDBox>
        ),
      });
    });

    return rows;
  };

  return {
    columns: [
      { Header: "Année Début", accessor: "startYear", align: "left" },
      { Header: "Année Fin", accessor: "endYear", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rows: getSchoolYearsRows(),
  };
}
