/**
 =========================================================
 * Material Dashboard 2 React - v2.0.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import TableCircularLoader from "components/reusable/TableCircularLoader";
import TableEmptyState from "components/reusable/TableEmptyState";

import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDInput from "components/MDInput";
import _ from "lodash";
import { RECEIVER_TYPE } from "../new-notice/utils/receiver-types";

// Data
import allNoticesData from "./data/allNoticesData";

import { navRoutes } from "../../constants/routes";

import * as types from "../../store/types";
import * as action from "../../store/actions";
import ModalNoticeDetails from "./data/ModalNoticeDetails";

function NoticesList() {
  const { columns, rows } = allNoticesData();

  const [filterClasses, setFilterClasses] = useState([]);
  const [selectedFilterClass, setSelectedFilterClass] = useState(null);
  const [filterReceiverType, setFilterReceiverType] = useState(-1);
  const [searchText, setSearchText] = useState("");

  const { classes } = useSelector((state) => state.schoolClasses);
  const { isLoadingNotices, noticesLoaded } = useSelector((state) => state.notices);
  const { toggleModalNoticeDetails } = useSelector((state) => state.modals);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action.getAllClasses());
  }, []);

  useEffect(() => {
    if (classes && classes.length > 0) {
      let classesOptions = [];
      classes.map((schoolClass) => {
        classesOptions.push({ id: schoolClass.id, label: schoolClass.className });
      });

      setFilterClasses(classesOptions);
    }
  }, [classes]);

  const handleSearchNotice = (e) => {
    console.log("Search", e.target.value);
    setSearchText(e.target.value);
    dispatch(
      action.getAllNotices({
        schoolClassId: selectedFilterClass?.id,
        searchQuery: e.target.value,
        receiverType: filterReceiverType !== -1 ? filterReceiverType : null,
      })
    );
  };

  const handleReceiverTypeChange = (value) => {
    console.log("Value", value);
    dispatch(
      action.getAllNotices({
        schoolClassId: selectedFilterClass?.id,
        searchQuery: searchText,
        receiverType: value !== -1 ? value : null,
      })
    );
  };

  console.log("filterReceiver", filterReceiverType);

  const handleFilterClassChange = (event, value) => {
    const id = value?.id;
    console.log("ValueId", id);
    console.log("typeof ValueId", typeof id);
    setSelectedFilterClass(value);
    dispatch(
      action.getAllNotices({
        schoolClassId: id,
        searchQuery: searchText,
        receiverType: filterReceiverType !== -1 ? filterReceiverType : null,
      })
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Liste des annonces
                </MDTypography>

                <MDButton component={Link} to={navRoutes.NEW_NOTICE}>
                  <MDBox display="flex" flexDirection="row" alignItems="center">
                    <Icon fontSize="small">add</Icon>
                    Ajouter
                  </MDBox>
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} display="flex" alignItems="center">
                <MDInput
                  sx={{ flex: 1 }}
                  label="Recherche"
                  InputProps={{ style: { paddingTop: 4, paddingBottom: 4 } }}
                  onChange={_.debounce((e) => handleSearchNotice(e), 500)}
                />

                <MDBox display="flex" sx={{ flex: 1, mx: 2 }}>
                  <FormControl sx={{ flex: 1 }} fullWidth>
                    <InputLabel id="typeSelectLabel">Type</InputLabel>
                    <Select
                      placeholder="Type"
                      id="typeSelect"
                      labelId="typeSelectLabel"
                      label="Type"
                      name="type"
                      onChange={(e) => {
                        setFilterReceiverType(e.target.value);
                        handleReceiverTypeChange(e.target.value);
                      }}
                      value={filterReceiverType}
                      fullWidth
                      IconComponent={() => (
                        <MDBox iconOnly pr={1}>
                          <Icon fontSize="medium" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                            arrow_drop_down
                          </Icon>
                        </MDBox>
                      )}
                      style={{ paddingTop: 13, paddingBottom: 10 }}
                      variant="outlined"
                    >
                      <MenuItem value={-1}>Tous les types</MenuItem>
                      <MenuItem value={RECEIVER_TYPE.EVERYONE}>A tout le monde</MenuItem>
                      <MenuItem value={RECEIVER_TYPE.ALL_PARENTS}>A tous les parents</MenuItem>
                      <MenuItem value={RECEIVER_TYPE.BY_CLASS}>Par classe</MenuItem>
                      <MenuItem value={RECEIVER_TYPE.BY_STUDENT}>Par élève</MenuItem>
                      <MenuItem value={RECEIVER_TYPE.ALL_TEACHERS}>A tous les enseignants</MenuItem>
                      <MenuItem value={RECEIVER_TYPE.BY_TEACHER_CLASS}>
                        Par classes d'enseignants
                      </MenuItem>
                      <MenuItem value={RECEIVER_TYPE.BY_TEACHER}>Par enseignant</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>

                <MDBox sx={{ flex: 1 }} display="flex">
                  <Autocomplete
                    sx={{ flex: 1 }}
                    disablePortal
                    id="combo-box"
                    options={filterClasses}
                    renderInput={(params) => <TextField {...params} label="Classe" />}
                    value={selectedFilterClass || null}
                    onChange={(event, newValue) => {
                      handleFilterClassChange(event, newValue);
                    }}
                  />
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                <TableCircularLoader isLoading={isLoadingNotices} />
                <TableEmptyState
                  isEmpty={rows.length === 0 && noticesLoaded}
                  text="Aucune annonce disponible"
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {toggleModalNoticeDetails && <ModalNoticeDetails />}

      <Footer />
    </DashboardLayout>
  );
}

export default NoticesList;
