import { combineReducers } from "redux";
import parentReducer from "./parentReducer";
import studentReducer from "./studentReducer";
import schoolYearReducer from "./schoolYearReducer";
import reportCardsReducer from "./reportCardsReducer";
import modalReducer from "./modalReducer";
import noticeReducer from "./noticeReducer";
import authReducer from "./authReducer";
import schoolClassReducer from "./schoolClassReducer";
import alertReducer from "./alertReducer";
import teacherReducer from "./teacherReducer";

export default combineReducers({
  students: studentReducer,
  parents: parentReducer,
  schoolYears: schoolYearReducer,
  reportCards: reportCardsReducer,
  modals: modalReducer,
  notices: noticeReducer,
  auth: authReducer,
  schoolClasses: schoolClassReducer,
  alerts: alertReducer,
  teachers: teacherReducer,
});
