import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import React from "react";

const TableCircularLoader = ({ isLoading }) =>
  isLoading && (
    <MDBox display="flex" justifyContent="center" alignItems="center" pb={3}>
      <CircularProgress color="info" />
    </MDBox>
  );

export default TableCircularLoader;
