import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Card,
  CircularProgress,
  Grid,
  Icon,
  Modal,
  TextField,
  Typography,
  typographyClasses,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import ErrorMessage from "components/reusable/ErrorMessage";
import MDButton from "components/MDButton";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { newTeacherSchema } from "layouts/new-teacher/validation-schema/newTeacherSchema";

import * as action from "../../../store/actions";
import * as types from "../../../store/types";

const ModalEditTeacher = ({ open = false, onClose }) => {
  const [dropDownClassesOptions, setDropDownClassesOptions] = useState([]);
  const [dropDownClassBlurred, setDropDownClassBlurred] = useState(false);
  const [selectedClasses, setSelectedClasses] = useState([]);

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    classes: [],
  });

  const { teacher, teacherLoaded, isUpdatingTeacher, teacherExists } = useSelector(
    (state) => state.teachers
  );

  const { classes, classesLoaded } = useSelector((state) => state.schoolClasses);

  const { teacherId } = useSelector((state) => state.modals);

  const dispatch = useDispatch();

  const initSelectedClasses = (allClasses) => {
    let selectedClassesArray = [];
    teacher.schoolClasses.map((schoolClass) => {
      const foundClasses = allClasses.find((item) => item.id === schoolClass.id);
      selectedClassesArray.push(foundClasses);
    });
    setSelectedClasses(selectedClassesArray);
  };

  useEffect(() => {
    dispatch(action.getTeacher(teacherId));
    dispatch(action.getAllClasses());
  }, [teacherId]);

  useEffect(
    () => () => {
      dispatch({ type: types.RESET_TEACHER_LOADED });
      dispatch(action.resetTeacherExistsFlag());
    },
    []
  );

  useEffect(() => {
    if (teacherLoaded && classesLoaded) {
      let allClasses = [];
      classes.map((schoolClass) =>
        allClasses.push({
          id: schoolClass.id,
          label: schoolClass.className,
        })
      );

      initSelectedClasses(allClasses);

      setInitialValues({
        firstName: teacher.firstName,
        lastName: teacher.lastName,
        email: teacher.email || "",
        phoneNumber: teacher.phoneNumber,
      });
    }
  }, [teacherLoaded, classesLoaded, teacherId]);

  useEffect(() => {
    if (classesLoaded) {
      let options = [];
      classes.map((schoolClass) => {
        options.push({
          id: schoolClass.id,
          label: schoolClass.className,
        });
      });
      setDropDownClassesOptions(options);
    }
  }, [classesLoaded, teacherId]);

  const handleEditTeacher = (data) => {
    console.log("handleEditTeacher", { ...data, id: teacher.id, schoolClasses: selectedClasses });
    dispatch(action.updateTeacher({ ...data, id: teacher.id, schoolClasses: selectedClasses }));
  };

  const handleClose = () => {
    dispatch({ type: types.TOGGLE_MODAL_EDIT_TEACHER, payload: { toggle: false } });
  };

  const handleSelectClassChange = (newValue) => {
    const uniqueClasses = Array.from(new Set(newValue.map((s) => s.id))).map((id) =>
      newValue.find((s) => s.id === id)
    );
    console.log("Unique", uniqueClasses);
    setSelectedClasses([...uniqueClasses]);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box width={600} style={{ background: "white", outline: "none", borderRadius: 8 }}>
        <MDBox mt={3} mb={3}>
          <Grid item xs={12} lg={12}>
            <MDBox p={2} display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="h5">Modification</MDTypography>
              <MDButton iconOnly onClick={handleClose}>
                <Icon fontSize="large">close</Icon>
              </MDButton>
            </MDBox>
            <Formik
              initialValues={initialValues}
              onSubmit={handleEditTeacher}
              validationSchema={newTeacherSchema}
              enableReinitialize
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <MDBox pt={2} px={2}>
                    <MDBox>
                      <MDInput
                        label="Nom"
                        name="lastName"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.lastName}
                        error={
                          props.touched.lastName &&
                          props.errors.lastName &&
                          props.errors.lastName !== ""
                        }
                        fullWidth
                      />
                      <ErrorMessage
                        message={
                          props.touched.lastName && props.errors.lastName && props.errors.lastName
                        }
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox pt={2} px={2}>
                    <MDBox>
                      <MDInput
                        label="Prénom"
                        name="firstName"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.firstName}
                        error={
                          props.touched.firstName &&
                          props.errors.firstName &&
                          props.errors.firstName !== ""
                        }
                        fullWidth
                      />
                      <ErrorMessage
                        message={
                          props.touched.firstName &&
                          props.errors.firstName &&
                          props.errors.firstName
                        }
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox pt={2} px={2}>
                    <MDBox>
                      <MDInput
                        label="Email"
                        name="email"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.email}
                        error={
                          props.touched.email && props.errors.email && props.errors.email !== ""
                        }
                        fullWidth
                      />
                      <ErrorMessage
                        message={props.touched.email && props.errors.email && props.errors.email}
                      />
                      <ErrorMessage
                        message={teacherExists && "Cette adresse Email est déjà utilisée."}
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox pt={2} px={2}>
                    <MDBox>
                      <MDInput
                        label="Téléphone"
                        name="phoneNumber"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.phoneNumber}
                        error={
                          props.touched.phoneNumber &&
                          props.errors.phoneNumber &&
                          props.errors.phoneNumber !== ""
                        }
                        fullWidth
                      />
                      <ErrorMessage
                        message={
                          props.touched.phoneNumber &&
                          props.errors.phoneNumber &&
                          props.errors.phoneNumber
                        }
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox pt={2} px={2}>
                    <MDBox>
                      <Autocomplete
                        disablePortal
                        multiple
                        id="combo-box"
                        options={dropDownClassesOptions}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Classes"
                            error={selectedClasses.length === 0 && dropDownClassBlurred}
                          />
                        )}
                        value={selectedClasses || []}
                        onChange={(event, newValue) => {
                          handleSelectClassChange(newValue);
                        }}
                        onBlur={() => setDropDownClassBlurred(true)}
                      />
                      <ErrorMessage
                        message={
                          selectedClasses.length === 0 &&
                          dropDownClassBlurred &&
                          "Vous devez sélectionner au moins une classe."
                        }
                      />

                      {/* <MDInput
                        label="Classe"
                        name="className"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.className}
                        error={props.touched.className && props.errors.className !== ""}
                        fullWidth
                      />
                      <ErrorMessage message={props.touched.className && props.errors.className} /> */}
                    </MDBox>
                  </MDBox>
                  <MDBox
                    mt={4}
                    mb={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="blue"
                  >
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="info"
                      style={{
                        paddingLeft: "50px",
                        paddingRight: "50px",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                      }}
                      disabled={isUpdatingTeacher || selectedClasses.length === 0}
                    >
                      {isUpdatingTeacher ? (
                        <CircularProgress color="white" size={20} />
                      ) : (
                        "Modifier"
                      )}
                    </MDButton>
                  </MDBox>
                </form>
              )}
            </Formik>
          </Grid>
        </MDBox>
      </Box>
    </Modal>
  );
};
export default ModalEditTeacher;
