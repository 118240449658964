import { navRoutes } from "constants/routes";
import Api from "../../api/Api";
import * as types from "../types";
import { showSuccessAlert } from "./alertActions";
import history from "../../router/router-history";

export const getAllSchoolYears = () => async (dispatch) => {
  const url = "/schoolYears";
  try {
    dispatch({ type: types.GET_ALL_SCHOOL_YEARS_REQUEST });

    const res = await Api.get(url);
    console.log("Res schoolYears ", res.data);
    dispatch({ type: types.GET_ALL_SCHOOL_YEARS_SUCCESS, payload: res.data });
  } catch (e) {
    console.log("Error Handling GetAllSchoolYears action ", e);
    dispatch({ type: types.GET_ALL_SCHOOL_YEARS_FAILURE });
  }
};

export const addSchoolYear = (schoolYear) => async (dispatch) => {
  const url = "/schoolYears";
  try {
    dispatch({ type: types.ADD_SCHOOL_YEAR_REQUEST });

    const res = await Api.post(url, schoolYear);
    console.log("Res addSchoolYear ", res.data);
    dispatch({ type: types.ADD_SCHOOL_YEAR_SUCCESS, payload: res.data });
    dispatch(
      showSuccessAlert({
        title: "Création effectuée",
        content: "Année scolaire créée avec succès.",
      })
    );
    history.push(navRoutes.SCHOOL_YEARS);
  } catch (e) {
    console.log("Error Handling addSchoolYear action ", e);
    dispatch({ type: types.ADD_SCHOOL_YEAR_FAILURE });
  }
};

export const deleteSchoolYear = (id) => async (dispatch) => {
  const url = `/schoolYears/${id}`;
  try {
    dispatch({ type: types.DELETE_SCHOOL_YEAR_REQUEST });

    const res = await Api.delete(url);
    console.log("Res deleteSchoolYear ", res.data);
    dispatch({ type: types.DELETE_SCHOOL_YEAR_SUCCESS, payload: res.data });
    dispatch(
      showSuccessAlert({
        title: "Suppression effectuée",
        content: "Année scolaire supprimée avec succès.",
      })
    );
    dispatch({ type: types.TOGGLE_MODAL_CONFIRM_DELETE, payload: { toggle: false } });
    dispatch(getAllSchoolYears());
    history.push(navRoutes.SCHOOL_YEARS);
  } catch (e) {
    console.log("Error Handling deleteSchoolYear action ", e);
    dispatch({ type: types.DELETE_SCHOOL_YEAR_FAILURE });
  }
};
