import * as types from "../types";

const INITIAL_STATE = {
  isAddingNotice: false,
  noticeAdded: false,

  isLoadingNotices: false,
  noticesLoaded: false,
  notices: [],
  notice: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADD_NOTICE_REQUEST:
      return {
        ...state,
        isAddingNotice: true,
      };

    case types.ADD_NOTICE_SUCCESS:
      return {
        ...state,
        isAddingNotice: false,
      };

    case types.ADD_NOTICE_FAILURE:
      return {
        ...state,
        isAddingNotice: false,
      };

    case types.GET_ALL_NOTICES_REQUEST:
      return {
        ...state,
        isLoadingNotices: true,
      };

    case types.GET_ALL_NOTICES_SUCCESS:
      return {
        ...state,
        isLoadingNotices: false,
        noticesLoaded: true,
        notices: action.payload,
      };

    case types.GET_ALL_NOTICES_FAILURE:
      return {
        ...state,
        isLoadingNotices: false,
      };

    case types.GET_NOTICE_REQUEST:
      return {
        ...state,
        isLoadingNotice: true,
      };

    case types.GET_NOTICE_SUCCESS:
      return {
        ...state,
        isLoadingNotice: false,
        noticeLoaded: true,
        notice: action.payload,
      };

    case types.GET_NOTICE_FAILURE:
      return {
        ...state,
        isLoadingNotice: false,
      };

    case types.RESET_NOTICE_DETAILS_LOADED:
      return {
        ...state,
        noticeLoaded: false,
      };

    default:
      return state;
  }
};
