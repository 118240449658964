/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import { Typography } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setSidenavColor } from "context";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { collapseCustomItem, collapseSubItem } from "./styles/sidenavCollapse";

function SidenavCollapse({
  icon,
  name,
  active,
  collapseName,
  hasChildren,
  nestedRoutes,
  routeKey,
  ...rest
}) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;

  const [expanded, setExpanded] = useState(false);

  const isLinkActive = collapseName?.split("/")[0] === routeKey;

  return (
    <div>
      <ListItem component="li" onClick={() => setExpanded(!expanded)}>
        <MDBox
          {...rest}
          sx={(theme) =>
            collapseCustomItem(theme, {
              active: isLinkActive,
              transparentSidenav,
              whiteSidenav,
              darkMode,
              sidenavColor,
            })
          }
        >
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, {
                transparentSidenav,
                whiteSidenav,
                darkMode,
                active: isLinkActive,
              })
            }
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active: isLinkActive })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />
          {hasChildren && (
            <Icon fontSize="small" style={{ color: "white" }}>
              {expanded ? "expand_less" : "expand_more"}
            </Icon>
          )}
        </MDBox>
      </ListItem>
      {hasChildren && expanded && (
        <div>
          {nestedRoutes.map((child) => (
            <NavLink key={child.key} to={child.route}>
              <ListItem component="li" key={child.key}>
                <MDBox
                  {...rest}
                  sx={(theme) =>
                    collapseSubItem(theme, {
                      active: child.key === collapseName,
                      transparentSidenav,
                      whiteSidenav,
                      darkMode,
                      sidenavColor,
                    })
                  }
                >
                  <ListItemIcon
                    sx={(theme) =>
                      collapseIconBox(theme, {
                        transparentSidenav,
                        whiteSidenav,
                        darkMode /* , active */,
                      })
                    }
                  >
                    {child.name[0]}
                  </ListItemIcon>

                  <ListItemText
                    primary={child.name}
                    sx={(theme) =>
                      collapseText(theme, {
                        miniSidenav,
                        transparentSidenav,
                        whiteSidenav,
                        active: child.key === collapseName,
                      })
                    }
                  />
                </MDBox>
              </ListItem>
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
