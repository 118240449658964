/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import allClassesData from "layouts/school-classes/data/allClassesData";
import MDButton from "components/MDButton";
import TableCircularLoader from "components/reusable/TableCircularLoader";
import TableEmptyState from "components/reusable/TableEmptyState";

import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { navRoutes } from "../../constants/routes";
import ModalConfirmDelete from "../../components/reusable/ModalConfirmDelete";

import * as types from "../../store/types";
import * as action from "../../store/actions";
import ModalDeleteStudents from "./items/ModalDeleteStudents";
import ModalTransferStudents from "./items/ModalTransferStudents";

function SchoolClassesList() {
  const { columns, rows } = allClassesData();

  const dispatch = useDispatch();

  const { toggleModalConfirmDelete, entityToDeleteId } = useSelector((state) => state.modals);

  const { isDeletingSchoolClass, classesLoaded, isLoadingClasses } = useSelector(
    (state) => state.schoolClasses
  );

  const handleCloseConfirmDeleteModal = () => {
    dispatch({
      type: types.TOGGLE_MODAL_CONFIRM_DELETE,
      payload: { toggle: false },
    });
  };

  const handleDeleteSchoolClass = () => {
    console.log("entityToDeleteId:", entityToDeleteId);
    dispatch(action.deleteSchoolClass(entityToDeleteId));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Liste des classes
                </MDTypography>

                <MDButton component={Link} to={navRoutes.NEW_CLASS}>
                  <MDBox display="flex" flexDirection="row" alignItems="center">
                    <Icon fontSize="small">add</Icon>
                    Ajouter
                  </MDBox>
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                <TableCircularLoader isLoading={isLoadingClasses} />
                <TableEmptyState
                  isEmpty={rows.length === 0 && classesLoaded}
                  text="Aucune classe disponible"
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <ModalConfirmDelete
        title="Confirmation de suppression"
        message="Êtes-vous certain de vouloir supprimer cette classe ?"
        open={toggleModalConfirmDelete}
        onClose={handleCloseConfirmDeleteModal}
        onConfirm={handleDeleteSchoolClass}
        isLoading={isDeletingSchoolClass}
      />

      <ModalDeleteStudents />

      <ModalTransferStudents />

      <Footer />
    </DashboardLayout>
  );
}

export default SchoolClassesList;
