/*eslint-disable */

import * as yup from "yup";
import { FORM_ERRORS } from "../../../constants/formErrors";

const checkIfCorrectYear = (value) => {
  const currentYear = new Date().getFullYear();
  return (
    value === new Date().getFullYear() ||
    value === new Date().getFullYear() + 1 ||
    value === new Date().getFullYear() - 1
  );
};

const checkIsEndYearCorrect = (value, startYear) => {
  console.log("Value", value);
  console.log("startYear", startYear);
  return value === startYear + 1;
};

export const newSchoolYearSchema = yup.object({
  startYear: yup
    .string()
    .matches(/^[0-9]{4}$/, FORM_ERRORS.INVALID_YEAR)
    .test("MIN_MAX_VALUE", FORM_ERRORS.INVALID_YEAR, (value) => checkIfCorrectYear(+value)),

  endYear: yup
    .string()
    .matches(/^[0-9]{4}$/, FORM_ERRORS.INVALID_YEAR)
    .test({
      name: "MIN_MAX_VALUE",
      exclusive: false,
      params: {},
      message: FORM_ERRORS.INVALID_YEAR,
      test: function (value) {
        return checkIsEndYearCorrect(+value, +this.parent.startYear);
      },
    }),
});
