import MDTypography from "components/MDTypography";
import React from "react";

const ErrorMessage = ({ message = "" }) =>
  message !== "" && (
    <MDTypography variant="caption" color="error">
      {message}
    </MDTypography>
  );

export default ErrorMessage;
