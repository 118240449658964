import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./reducers";

const middleware = [thunk];

// let composeEnhancers = compose;

export const configuredStore = createStore(
  rootReducer,

  applyMiddleware(...middleware)
);
