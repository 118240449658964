import * as types from "../types";

const INITIAL_STATE = {
  teachers: [],
  isLoadingTeachers: false,
  teachersLoaded: false,

  isAddingTeacher: false,
  teacherAdded: false,

  isUpdatingTeacher: false,
  teacherUpdated: false,

  isDeletingTeacher: false,
  teacherDeleted: false,

  teacher: null,
  isLoadingTeacher: false,
  teacherLoaded: false,

  teacherExists: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADD_TEACHER_REQUEST:
      return {
        ...state,
        isAddingTeacher: true,
        teacherExists: false,
      };

    case types.ADD_TEACHER_SUCCESS:
      return {
        ...state,
        isAddingTeacher: false,
        teacherAdded: true,
        teacherExists: false,
      };

    case types.ADD_TEACHER_FAILURE:
      return {
        ...state,
        isAddingTeacher: false,
      };

    case types.GET_ALL_TEACHERS_REQUEST:
      return {
        ...state,
        isLoadingTeachers: true,
      };

    case types.GET_ALL_TEACHERS_SUCCESS:
      return {
        ...state,
        isLoadingTeachers: false,
        teachers: action.payload,
        teachersLoaded: true,
      };

    case types.GET_ALL_TEACHERS_FAILURE:
      return {
        ...state,
        isLoadingTeachers: false,
      };

    case types.GET_TEACHER_REQUEST:
      return {
        ...state,
        isLoadingTeacher: true,
      };

    case types.GET_TEACHER_SUCCESS:
      return {
        ...state,
        isLoadingTeacher: false,
        teacher: action.payload,
        teacherLoaded: true,
      };

    case types.GET_TEACHER_FAILURE:
      return {
        ...state,
        isLoadingTeacher: false,
      };

    case types.UPDATE_TEACHER_REQUEST:
      return {
        ...state,
        isUpdatingTeacher: true,
        teacherExists: false,
      };

    case types.UPDATE_TEACHER_SUCCESS:
      return {
        ...state,
        isUpdatingTeacher: false,
        teacherUpdated: true,
        teacherExists: false,
      };

    case types.UPDATE_TEACHER_FAILURE:
      return {
        ...state,
        isUpdatingTeacher: false,
      };

    case types.ADD_OR_UPDATE_TEACHER_FAILURE_CONFLICT:
      return {
        ...state,
        teacherExists: true,
      };

    case types.DELETE_TEACHER_REQUEST:
      return {
        ...state,
        isDeletingTeacher: true,
      };

    case types.DELETE_TEACHER_SUCCESS:
      return {
        ...state,
        isDeletingTeacher: false,
      };

    case types.DELETE_TEACHER_FAILURE:
      return {
        ...state,
        isDeletingTeacher: false,
      };

    case types.RESET_TEACHER_LOADED:
      return {
        ...state,
        teacherLoaded: false,
      };

    case types.RESET_TEACHER_DELETED:
      return {
        ...state,
        teacherDeleted: false,
      };

    case types.RESET_TEACHER_EXISTS:
      return {
        ...state,
        teacherExists: false,
      };

    default:
      return state;
  }
};
