import { StatusCodes } from "http-status-codes";
import { navRoutes } from "constants/routes";
import { FORM_ERRORS } from "../../constants/formErrors";
import Api from "../../api/Api";
import history from "../../router/router-history";
import * as types from "../types";

export const login = (credentials, callback) => async (dispatch) => {
  const url = "/auth/login";
  try {
    dispatch({ type: types.LOGIN_REQUEST });
    const response = await Api.post(url, credentials);

    console.log("Response login ", response.data);

    dispatch({ type: types.LOGIN_SUCCESS });

    localStorage.setItem("token", response.data.token);
    localStorage.setItem("loggedIn", true);
    console.log("LocalStorage", localStorage.getItem("token"));
    // console.log("history?.location?.pathname", history?.location?.pathname);
    // history.push(navRoutes.STUDENTS);
    callback();
  } catch (e) {
    console.log("Error handling login action ", e);
    const status = (e.response && e.response.status) || null;
    dispatch({ type: types.LOGIN_FAILURE, status });
  }
};

export const register = (data) => async (dispatch) => {
  const url = "/auth/register";
  try {
    dispatch({ type: types.REGISTER_REQUEST });
    const response = await Api.post(url, data);

    console.log("Response Register ", response.data);

    dispatch({ type: types.REGISTER_SUCCESS });
    history.push(navRoutes.LOGIN);
  } catch (e) {
    console.log("Error handling register action ", e.response);
    const { status } = e.response;
    dispatch({
      type: types.REGISTER_FAILURE,
      payload: status === StatusCodes.CONFLICT ? FORM_ERRORS.EMAIL_CONFLICT : "",
    });
  }
};

export const checkIfUserAuthed = () => (dispatch) => {
  try {
    const isAuthed = localStorage.getItem("loggedIn") === "true";
    dispatch({ type: types.USER_AUTHED, payload: isAuthed });
  } catch (e) {
    console.log("Error handling checkIfUserAuthed action", e);
  }
};

export const logout = (callback) => (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("loggedIn");
  dispatch({ type: types.LOGOUT_SUCCESS });
  callback();
};
