import React, { useEffect, useState } from "react";
import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Icon,
  Modal,
  Radio,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import ErrorMessage from "components/reusable/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";

import { Formik } from "formik";
import * as yup from "yup";
import { FORM_ERRORS } from "../../../constants/formErrors";
import * as action from "../../../store/actions";

const emailValidationSchema = yup.object({
  email: yup.string().email(FORM_ERRORS.INVALID_EMAIL).required(FORM_ERRORS.REQUIRED),
});

const ModalChooseReceiver = ({ open = false, onClose, reportCardId, isLoading }) => {
  const [isDefaultMethod, setIsDefaultMethod] = useState(true);
  const [customEmail, setCustomEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isWithoutParentEmail, setIsWithoutParentEmail] = useState(false);
  const [viaSms, setViaSms] = useState(true);

  const {
    singleReportCard,
    isSendingSingleReportCard,
    singleReportCardSent,
    singleReportCardLoaded,
  } = useSelector((state) => state.reportCards);

  const dispatch = useDispatch();

  useEffect(() => {
    if (reportCardId) {
      dispatch(action.getReportCard(reportCardId));
    }
  }, [reportCardId]);

  useEffect(() => {
    if (singleReportCard && singleReportCardLoaded) {
      if (singleReportCard.parents.length === 1 && singleReportCard.parents[0].email === null) {
        setIsWithoutParentEmail(true);
      }
    }
  }, [singleReportCard, singleReportCardLoaded]);

  useEffect(() => {
    setIsWithoutParentEmail(false);
  }, []);

  const handleChangeMethod = (e) => {
    const { value } = e.target;
    console.log("Value", value);
    setIsDefaultMethod(value === "default");
  };

  const handleCustomEmailChange = (e) => {
    const { value } = e.target;
    setCustomEmail(value);
    setIsValidEmail(
      yup
        .string()
        .email(FORM_ERRORS.INVALID_EMAIL)
        .required(FORM_ERRORS.REQUIRED)
        .isValidSync(value)
    );
  };

  const handleSendReportCard = () => {
    dispatch(action.sendReportCard(reportCardId, customEmail, viaSms));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <MDBox
        width={600}
        py={2}
        px={2}
        style={{ background: "white", outline: "none", borderRadius: 8 }}
      >
        <MDTypography>
          Envoi du bulletin au(x) parent(s) de{" "}
          {singleReportCard &&
            `${singleReportCard.student.firstName} ${singleReportCard.student.lastName}`}
        </MDTypography>
        <Divider />
        <MDBox>
          <MDTypography fontSize="medium">Sélectionnez la méthode : </MDTypography>

          <MDBox display="flex" alignItems="center" pt={1}>
            <FormControlLabel
              control={<Checkbox checked={viaSms} onChange={() => setViaSms(!viaSms)} />}
              label={
                <MDTypography fontSize="medium" fontWeight="bold" sx={{ paddingLeft: "3px" }}>
                  Envoyer par SMS
                </MDTypography>
              }
            />
          </MDBox>
          <MDBox pl={3}>
            {singleReportCard &&
              singleReportCard.parents &&
              singleReportCard.parents.map(
                (parent) =>
                  parent.phoneNumber && (
                    <MDBox key={parent.id} display="flex" alignItems="center">
                      <MDTypography component="li" fontSize="medium">
                        {parent.phoneNumber}
                      </MDTypography>
                    </MDBox>
                  )
              )}
          </MDBox>

          <MDBox display="flex" alignItems="center" pt={1}>
            <input
              type="radio"
              name="radioMethod"
              onChange={handleChangeMethod}
              value="default"
              defaultChecked={!isWithoutParentEmail}
              disabled={isWithoutParentEmail}
              style={{ height: 15, width: 15, marginRight: 10 }}
            />
            <MDTypography fontSize="medium" fontWeight="bold" pl={1}>
              Email(s) par défaut
            </MDTypography>
          </MDBox>

          <MDBox pl={3}>
            {singleReportCard &&
              singleReportCard.parents &&
              singleReportCard.parents.map(
                (parent) =>
                  parent.email && (
                    <MDBox key={parent.id} display="flex" alignItems="center">
                      <MDTypography component="li" fontSize="medium">
                        {parent.email}
                      </MDTypography>
                    </MDBox>
                  )
              )}
          </MDBox>

          <MDBox display="flex" alignItems="center" pt={2}>
            <input
              type="radio"
              defaultChecked={false}
              name="radioMethod"
              onChange={handleChangeMethod}
              value="custom"
              style={{ height: 15, width: 15, marginRight: 10 }}
            />
            <MDTypography fontSize="medium" fontWeight="bold" pl={1}>
              Email personnalisé
            </MDTypography>
          </MDBox>
          {!isDefaultMethod && (
            <MDBox px={3}>
              <MDBox pt={1}>
                <MDInput
                  error={!isValidEmail}
                  placeholder="Adresse Email"
                  fullWidth
                  onChange={handleCustomEmailChange}
                />
                <ErrorMessage message={!isValidEmail && "Le format de l'email est incorrect."} />
              </MDBox>
            </MDBox>
          )}
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="center" alignItems="center" mt={3}>
          <MDBox mr={1}>
            <MDButton color="secondary" onClick={onClose} disabled={isSendingSingleReportCard}>
              Annuler
            </MDButton>
          </MDBox>
          <MDBox ml={1}>
            <MDButton
              color="info"
              onClick={handleSendReportCard}
              disabled={
                isSendingSingleReportCard ||
                (!isDefaultMethod && (!isValidEmail || customEmail === "")) ||
                (isDefaultMethod && isWithoutParentEmail)
              }
            >
              {isSendingSingleReportCard ? <CircularProgress color="white" size={20} /> : "Envoyer"}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Modal>
  );
};

export default ModalChooseReceiver;
