import { navRoutes } from "constants/routes";

import Api from "../../api/Api";
import * as types from "../types";
import { showSuccessAlert } from "./alertActions";
import history from "../../router/router-history";
import { getUrlWithQueryParams } from "../../utils/queryBuilder";
import { closeModalNoticeDetails } from "./modalActions";

export const getAllNotices =
  ({ searchQuery, receiverType, schoolClassId } = {}) =>
  async (dispatch) => {
    const url = getUrlWithQueryParams("notices", { searchQuery, receiverType, schoolClassId });
    try {
      dispatch({ type: types.GET_ALL_NOTICES_REQUEST });
      const res = await Api.get(url);
      console.log("Res getAllNotices", res.data);

      dispatch({ type: types.GET_ALL_NOTICES_SUCCESS, payload: res.data });
    } catch (e) {
      console.error("Error handling getAllNotices Action", e);
      dispatch({ type: types.GET_ALL_NOTICES_FAILURE });
    }
  };

export const sendNotice = (data) => async (dispatch) => {
  const url = "notices";
  try {
    dispatch({ type: types.ADD_NOTICE_REQUEST });

    const res = await Api.post(url, data);
    console.log("Res SendNotice ", res.data);

    dispatch({ type: types.ADD_NOTICE_SUCCESS });
    dispatch(
      showSuccessAlert({
        title: "Envoi effectué",
        content: "Annonce envoyée avec succès",
      })
    );
    dispatch({ type: types.TOGGLE_MODAL_SENDING_NOTICES_PROGRESS, payload: { toggle: false } });
    dispatch(getAllNotices());
    history.push(navRoutes.NOTICES);
  } catch (e) {
    console.log("Error handling SendNotice Action ", e);
    dispatch({ type: types.ADD_NOTICE_FAILURE });
    dispatch({ type: types.TOGGLE_MODAL_SENDING_NOTICES_PROGRESS, payload: { toggle: false } });
  }
};

export const getNotice = (noticeId) => async (dispatch) => {
  const url = `notices/${noticeId}`;
  try {
    dispatch({ type: types.GET_NOTICE_REQUEST });
    const res = await Api.get(url);
    console.log("Res getNotice", res.data);
    dispatch({ type: types.GET_NOTICE_SUCCESS, payload: res.data });
  } catch (e) {
    console.error("Error handling getNotice Action", e);
    dispatch({ type: types.GET_NOTICE_FAILURE });
    dispatch(closeModalNoticeDetails());
  }
};

export const uploadFile = async (file) => {
  const url = "notices/files/upload";
  try {
    const response = await Api.post(url, file);
    return response.data;
  } catch (e) {
    console.error("Error handling UploadFile", e);
    return null;
  }
};
