import React, { useEffect, useState } from "react";
import { Divider, Icon, Modal, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import TableCircularLoader from "../../../components/reusable/TableCircularLoader";
import * as action from "../../../store/actions";
import * as types from "../../../store/types";

const ModalNoticeDetails = () => {
  const { notice, isLoadingNotice, noticeLoaded } = useSelector((state) => state.notices);
  const { toggleModalNoticeDetails, noticeId } = useSelector((state) => state.modals);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action.getNotice(noticeId));
  }, [noticeId]);

  useEffect(
    () => () => {
      dispatch({ type: types.RESET_NOTICE_DETAILS_LOADED });
    },
    []
  );

  console.log("NoticeId", noticeId);
  console.log("Notice", notice);
  console.log("IsLoa", isLoadingNotice);
  console.log("NoticeLoaded", noticeLoaded);

  const handleCloseModal = () => {
    dispatch(action.closeModalNoticeDetails());
  };

  return (
    <Modal
      open={toggleModalNoticeDetails}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <MDBox
        width={600}
        py={2}
        px={2}
        style={{ background: "white", outline: "none", borderRadius: 8 }}
      >
        {noticeLoaded ? (
          <>
            <MDBox display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography>Annonce du 28-04-2022 à 11:40</MDTypography>
              <MDButton iconOnly onClick={handleCloseModal}>
                <Icon fontSize="large">close</Icon>
              </MDButton>
            </MDBox>
            <Divider />
            <MDBox display="flex" flexDirection="row" alignItems="center" pb={1}>
              <MDTypography sx={{ fontSize: 16 }}>Sujet : </MDTypography>
              <MDTypography pl={1} sx={{ fontSize: 16 }} fontWeight="bold">
                {notice.subject}
              </MDTypography>
            </MDBox>

            <MDBox display="flex" flexDirection="row" alignItems="center" pb={1}>
              <MDTypography sx={{ fontSize: 16 }}>Type : </MDTypography>
              <MDTypography pl={1} sx={{ fontSize: 16 }} fontWeight="bold">
                {notice.type?.label}
              </MDTypography>
            </MDBox>

            {notice.receivers && notice.receivers.length > 0 && (
              <MDBox display="flex" flexDirection="row" alignItems="center" pb={1}>
                <MDTypography sx={{ fontSize: 16 }}>Destinataire(s) : </MDTypography>
                <MDTypography pl={1} sx={{ fontSize: 16 }} fontWeight="bold">
                  {notice.receivers.map(
                    (receiver, index) =>
                      receiver.label + (index < notice.receivers.length - 1 ? ", " : "")
                  )}
                </MDTypography>
              </MDBox>
            )}

            {notice.attachments && notice.attachments.length > 0 && (
              <MDBox pb={1}>
                <MDTypography sx={{ fontSize: 16 }}>Pièce(s) jointe(s) : </MDTypography>
                {notice.attachments.map((attachment, index) => (
                  <MDBox px={2} key={attachment.fileName}>
                    <MDTypography
                      display="inline"
                      component="a"
                      target="_blank"
                      href={attachment.url}
                      variant="subtitle2"
                      fontWeight="bold"
                    >
                      Pièce jointe {index + 1}
                    </MDTypography>
                  </MDBox>
                ))}
              </MDBox>
            )}

            <MDBox>
              <MDTypography sx={{ fontSize: 16 }}>Contenu</MDTypography>
              <MDBox px={2} py={1} sx={{ border: 1, borderRadius: "8px", borderColor: "#ced4da" }}>
                <div style={{ fontSize: 16 }}>{parse(DOMPurify.sanitize(notice.content))}</div>
              </MDBox>
            </MDBox>

            <Divider />
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDButton variant="outlined" color="info" onClick={handleCloseModal}>
                Fermer
              </MDButton>
            </MDBox>
          </>
        ) : (
          <TableCircularLoader isLoading={isLoadingNotice} />
        )}
      </MDBox>
    </Modal>
  );
};
export default ModalNoticeDetails;
