import * as types from "../types";

const INITIAL_STATE = {
  students: [],
  isLoadingStudents: false,
  studentsLoaded: false,

  isAddingStudent: false,
  studentAdded: false,

  student: null,
  isLoadingStudent: false,
  studentLoaded: false,

  isUpdatingStudent: false,
  studentUpdated: false,

  isDeletingStudent: false,
  studentDeleted: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_ALL_STUDENTS_REQUEST:
      return {
        ...state,
        isLoadingStudents: true,
      };

    case types.GET_ALL_STUDENTS_SUCCESS:
      return {
        ...state,
        students: action.payload,
        isLoadingStudents: false,
        studentsLoaded: true,
      };

    case types.GET_ALL_STUDENTS_FAILURE:
      return {
        ...state,
        isLoadingStudents: false,
      };

    case types.ADD_STUDENT_REQUEST:
      return {
        ...state,
        isAddingStudent: true,
      };

    case types.ADD_STUDENT_SUCCESS:
      return {
        ...state,
        isAddingStudent: false,
        studentAdded: true,
        students: [...state.students, action.payload],
      };

    case types.ADD_STUDENT_FAILURE:
      return {
        ...state,
        isAddingStudent: false,
      };

    case types.GET_STUDENT_REQUEST:
      return {
        ...state,
        isLoadingStudent: true,
        studentLoaded: false,
      };

    case types.GET_STUDENT_SUCCESS:
      return {
        ...state,
        isLoadingStudent: false,
        student: action.payload,
        studentLoaded: true,
      };

    case types.GET_STUDENT_FAILURE:
      return {
        ...state,
        isLoadingStudent: false,
        studentLoaded: false,
      };

    case types.UPDATE_STUDENT_REQUEST:
      return {
        ...state,
        isUpdatingStudent: true,
        studentUpdated: false,
      };

    case types.UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        isUpdatingStudent: false,
        studentUpdated: true,
      };

    case types.UPDATE_STUDENT_FAILURE:
      return {
        ...state,
        isUpdatingStudent: false,
        studentUpdated: false,
      };

    case types.DELETE_STUDENT_REQUEST:
      return {
        ...state,
        isDeletingStudent: true,
        studentDeleted: false,
      };

    case types.DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        isDeletingStudent: false,
        studentDeleted: true,
      };

    case types.DELETE_STUDENT_FAILURE:
      return {
        ...state,
        isDeletingStudent: false,
        studentDeleted: false,
      };

    case types.RESET_STUDENT_DELETED:
      return {
        ...state,
        studentDeleted: false,
      };

    default:
      return state;
  }
};
