import React, { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import MDTypography from "components/MDTypography";

export default function classStudents(students, onChangeSelection, open = false) {
  const [allStudentsSelected, setAllStudentsSelected] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);

  useEffect(() => {
    if (!open) {
      setAllStudentsSelected(false);
      setSelectedStudents([]);
    }
  }, [open]);

  console.log("allStudentsSelected", allStudentsSelected);

  const Student = ({ name }) => (
    <MDTypography display="block" variant="button" fontWeight="medium">
      {name}
    </MDTypography>
  );
  console.log("SelectedStudents", selectedStudents);

  const getStudentsRows = () => {
    let rows = [];

    students?.map((student) => {
      rows.push({
        selectStudent: (
          <Checkbox
            onChange={() => handleSelectStudent(student.id)}
            checked={selectedStudents?.includes(student.id)}
          />
        ),
        student: <Student name={`${student.firstName} ${student.lastName}`} />,
      });
    });

    return rows;
  };

  const handleSelectStudent = (id) => {
    const student = selectedStudents.find((item) => item === id);
    let newSelectedStudents = [];
    if (student) {
      newSelectedStudents = selectedStudents.filter((item) => item !== id);
    } else {
      newSelectedStudents = [...selectedStudents, id];
    }

    setSelectedStudents(newSelectedStudents);
    setAllStudentsSelected(newSelectedStudents.length === students.length);
    onChangeSelection(newSelectedStudents);
  };

  const handleSelectAllStudents = () => {
    if (allStudentsSelected) {
      setSelectedStudents([]);
      onChangeSelection([]);
    } else {
      let newSelectedStudents = [];
      students.map((student) => {
        newSelectedStudents.push(student.id);
      });

      setSelectedStudents(newSelectedStudents);
      onChangeSelection(newSelectedStudents);
    }

    setAllStudentsSelected(!allStudentsSelected);
  };

  return {
    columns: [
      {
        Header: <Checkbox onChange={handleSelectAllStudents} checked={allStudentsSelected} />,
        accessor: "selectStudent",
        width: "10%",
        align: "left",
      },
      { Header: "Élève", accessor: "student", align: "center" },
    ],

    rows: getStudentsRows(),
  };
}
