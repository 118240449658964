import * as types from "../types";

const INITIAL_STATE = {
  isLoadingClasses: false,
  classesLoaded: false,
  classes: [],

  isAddingClass: false,
  classAdded: false,

  isDeletingSchoolClass: false,
  schoolClassDeleted: false,

  isTransferringStudents: false,
  studentsTransferred: false,

  isDeletingClassStudents: false,
  classStudentsDeleted: false,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_ALL_SCHOOL_CLASSES_REQUEST:
      return {
        ...state,
        isLoadingClasses: true,
      };

    case types.GET_ALL_SCHOOL_CLASSES_SUCCESS:
      return {
        ...state,
        isLoadingClasses: false,
        classesLoaded: true,
        classes: action.payload,
      };

    case types.GET_ALL_SCHOOL_CLASSES_FAILURE:
      return {
        ...state,
        isLoadingClasses: false,
      };

    case types.ADD_SCHOOL_CLASS_REQUEST:
      return {
        ...state,
        isAddingClass: true,
      };

    case types.ADD_SCHOOL_CLASS_SUCCESS:
      return {
        ...state,
        isAddingClass: false,
        classAdded: true,
      };

    case types.ADD_SCHOOL_CLASS_FAILURE:
      return {
        ...state,
        isAddingClass: false,
      };

    case types.DELETE_SCHOOL_CLASS_REQUEST:
      return {
        ...state,
        isDeletingSchoolClass: true,
      };

    case types.DELETE_SCHOOL_CLASS_SUCCESS:
      return {
        ...state,
        isDeletingSchoolClass: false,
        schoolClassDeleted: true,
      };

    case types.DELETE_SCHOOL_CLASS_FAILURE:
      return {
        ...state,
        isDeletingSchoolClass: false,
      };

    case types.RESET_SCHOOL_CLASS_DELETED:
      return {
        ...state,
        schoolClassDeleted: false,
      };

    case types.DELETE_CLASS_STUDENTS_REQUEST:
      return {
        ...state,
        isDeletingClassStudents: true,
      };

    case types.DELETE_CLASS_STUDENTS_SUCCESS:
      return {
        ...state,
        isDeletingClassStudents: false,
        classStudentsDeleted: true,
      };

    case types.DELETE_CLASS_STUDENTS_FAILURE:
      return {
        ...state,
        isDeletingClassStudents: false,
      };

    case types.TRANSFER_STUDENTS_REQUEST:
      return {
        ...state,
        isTransferringStudents: true,
      };

    case types.TRANSFER_STUDENTS_SUCCESS:
      return {
        ...state,
        isTransferringStudents: false,
        studentsTransferred: true,
      };

    case types.TRANSFER_STUDENTS_FAILURE:
      return {
        ...state,
        isTransferringStudents: false,
      };

    default:
      return state;
  }
};
