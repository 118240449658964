import Api from "api/Api";
import { navRoutes } from "constants/routes";
import * as types from "../types";
import { showSuccessAlert } from "./alertActions";
import history from "../../router/router-history";

export const getAllClasses = () => async (dispatch) => {
  const url = "classes";
  try {
    dispatch({ type: types.GET_ALL_SCHOOL_CLASSES_REQUEST });
    const response = await Api.get(url);
    console.log("Response getAllClasses", response.data);
    dispatch({ type: types.GET_ALL_SCHOOL_CLASSES_SUCCESS, payload: response.data });
  } catch (e) {
    console.log("Error handling getAllClasses action ", e);
    dispatch({ type: types.GET_ALL_SCHOOL_CLASSES_FAILURE });
  }
};

export const addClass = (data) => async (dispatch) => {
  const url = "classes";
  try {
    dispatch({ type: types.ADD_SCHOOL_CLASS_REQUEST });
    const response = await Api.post(url, data);
    console.log("Response addClass", response.data);
    dispatch({ type: types.ADD_SCHOOL_CLASS_SUCCESS });
    dispatch(
      showSuccessAlert({
        title: "Création effectuée",
        content: "Classe créée avec succès.",
      })
    );
    history.push(navRoutes.CLASSES);
  } catch (e) {
    console.log("Error handling addClass action ", e);
    dispatch({ type: types.ADD_SCHOOL_CLASS_FAILURE });
  }
};

export const deleteSchoolClass = (id) => async (dispatch) => {
  const url = `/classes/${id}`;
  try {
    dispatch({ type: types.DELETE_SCHOOL_CLASS_REQUEST });

    const res = await Api.delete(url);
    console.log("Res deleteSchoolClass ", res.data);
    dispatch({ type: types.DELETE_SCHOOL_CLASS_SUCCESS, payload: res.data });
    dispatch(
      showSuccessAlert({
        title: "Suppression effectuée",
        content: "Classe supprimée avec succès.",
      })
    );

    dispatch({ type: types.TOGGLE_MODAL_CONFIRM_DELETE, payload: { toggle: false } });
    dispatch({ type: types.RESET_SCHOOL_CLASS_DELETED });
    dispatch(getAllClasses());
    history.push(navRoutes.CLASSES);
  } catch (e) {
    console.log("Error Handling deleteSchoolClass action ", e);
    dispatch({ type: types.DELETE_SCHOOL_CLASS_FAILURE });
  }
};

export const deleteClassStudents =
  ({ schoolClassId, isCustomDelete = false, students = [] }) =>
  async (dispatch) => {
    const url = "/classes/deleteStudents";
    try {
      dispatch({ type: types.DELETE_CLASS_STUDENTS_REQUEST });
      const res = await Api.post(url, { schoolClassId, isCustomDelete, students });
      console.log("Res deleteClassStudents", res);

      dispatch({ type: types.DELETE_CLASS_STUDENTS_SUCCESS });
      dispatch(
        showSuccessAlert({
          title: "Suppression effectuée",
          content: "Élèves supprimés avec succès.",
        })
      );

      dispatch({ type: types.TOGGLE_MODAL_DELETE_CLASS_STUDENTS, payload: { toggle: false } });
      dispatch(getAllClasses());
      history.push(navRoutes.CLASSES);
    } catch (error) {
      console.log("Error handling deleteClassStudents action ", e);
      dispatch({ type: types.DELETE_CLASS_STUDENTS_FAILURE });
    }
  };

export const transferStudents =
  ({ srcClassId, destClassId, isCustomTransfer = false, students = [] }) =>
  async (dispatch) => {
    const url = "/classes/transferStudents";
    try {
      dispatch({ type: types.TRANSFER_STUDENTS_REQUEST });
      const res = await Api.post(url, { srcClassId, destClassId, isCustomTransfer, students });
      console.log("Res transferStudents", res.data);

      dispatch({ type: types.TRANSFER_STUDENTS_SUCCESS, payload: res.data });
      dispatch(
        showSuccessAlert({
          title: "Transfert effectué",
          content: "Élèves transférés avec succès.",
        })
      );

      dispatch({ type: types.TOGGLE_MODAL_TRANSFER_STUDENTS, payload: { toggle: false } });
      dispatch(getAllClasses());
      history.push(navRoutes.CLASSES);
    } catch (e) {
      console.log("Error handling transferStudents action", e);
      dispatch({ type: types.TRANSFER_STUDENTS_FAILURE });
    }
  };
