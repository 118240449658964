import { StatusCodes } from "http-status-codes";
import * as types from "../types";

const INITIAL_STATE = {
  isConnected: false,
  isLogging: false,
  invalidCredentials: false,
  isRegistering: false,
  registerError: "",
  isAuthed: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        isLogging: true,
        invalidCredentials: false,
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLogging: false,
        isConnected: true,
        invalidCredentials: false,
        isAuthed: true,
      };

    case types.LOGIN_FAILURE:
      return {
        ...state,
        isLogging: false,
        invalidCredentials: action.status === StatusCodes.UNAUTHORIZED,
        isAuthed: false,
      };

    case types.REGISTER_REQUEST:
      return {
        ...state,
        isRegistering: true,
        registerError: "",
      };

    case types.REGISTER_SUCCESS:
      return {
        ...state,
        isRegistering: false,
        registerError: "",
      };

    case types.REGISTER_FAILURE:
      return {
        ...state,
        isRegistering: false,
        registerError: action.payload,
      };

    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthed: false,
        isConnected: false,
      };

    case types.USER_AUTHED:
      return {
        ...state,
        isAuthed: action.payload,
      };

    default:
      return state;
  }
};
