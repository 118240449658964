export const GET_ALL_STUDENTS_REQUEST = "GET_ALL_STUDENTS_REQUEST";
export const GET_ALL_STUDENTS_SUCCESS = "GET_ALL_STUDENTS_SUCCESS";
export const GET_ALL_STUDENTS_FAILURE = "GET_ALL_STUDENTS_FAILURE";

export const GET_STUDENT_REQUEST = "GET_STUDENT_REQUEST";
export const GET_STUDENT_SUCCESS = "GET_STUDENT_SUCCESS";
export const GET_STUDENT_FAILURE = "GET_STUDENT_FAILURE";

export const ADD_STUDENT_REQUEST = "ADD_STUDENT_REQUEST";
export const ADD_STUDENT_SUCCESS = "ADD_STUDENT_SUCCESS";
export const ADD_STUDENT_FAILURE = "ADD_STUDENT_FAILURE";

export const DELETE_STUDENT_REQUEST = "DELETE_STUDENT_REQUEST";
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
export const DELETE_STUDENT_FAILURE = "DELETE_STUDENT_FAILURE";

export const RESET_STUDENT_DELETED = "RESET_STUDENT_DELETED";

export const UPDATE_STUDENT_REQUEST = "UPDATE_STUDENT_REQUEST";
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS";
export const UPDATE_STUDENT_FAILURE = "UPDATE_STUDENT_FAILURE";

export const GET_ALL_PARENTS_REQUEST = "GET_ALL_PARENTS_REQUEST";
export const GET_ALL_PARENTS_SUCCESS = "GET_ALL_PARENTS_SUCCESS";
export const GET_ALL_PARENTS_FAILURE = "GET_ALL_PARENTS_FAILURE";

export const ADD_PARENT_REQUEST = "ADD_PARENT_REQUEST";
export const ADD_PARENT_SUCCESS = "ADD_PARENT_SUCCESS";
export const ADD_PARENT_FAILURE = "ADD_PARENT_FAILURE";

export const GET_PARENT_REQUEST = "GET_PARENT_REQUEST";
export const GET_PARENT_SUCCESS = "GET_PARENT_SUCCESS";
export const GET_PARENT_FAILURE = "GET_PARENT_FAILURE";

export const UPDATE_PARENT_REQUEST = "UPDATE_PARENT_REQUEST";
export const UPDATE_PARENT_SUCCESS = "UPDATE_PARENT_SUCCESS";
export const UPDATE_PARENT_FAILURE = "UPDATE_PARENT_FAILURE";

export const DELETE_PARENT_REQUEST = "DELETE_PARENT_REQUEST";
export const DELETE_PARENT_SUCCESS = "DELETE_PARENT_SUCCESS";
export const DELETE_PARENT_FAILURE = "DELETE_PARENT_FAILURE";

export const ADD_OR_UPDATE_PARENT_FAILURE_CONFLICT = "ADD_OR_UPDATE_PARENT_FAILURE_CONFLICT";

export const RESET_PARENT_LOADED = "RESET_PARENT_LOADED";
export const RESET_PARENT_DELETED = "RESET_PARENT_DELETED";
export const RESET_PARENT_EXISTS = "RESET_PARENT_EXISTS";

export const GET_STUDENT_PARENTS_REQUEST = "GET_STUDENT_PARENTS_REQUEST";
export const GET_STUDENT_PARENTS_SUCCESS = "GET_STUDENT_PARENTS_SUCCESS";
export const GET_STUDENT_PARENTS_FAILURE = "GET_STUDENT_PARENTS_FAILURE";

export const GET_CURRENT_TRIMESTER_REPORT_CARDS = "GET_CURRENT_TRIMESTER_REPORT_CARDS";

export const GET_ALL_REPORT_CARDS_REQUEST = "GET_ALL_REPORT_CARDS_REQUEST";
export const GET_ALL_REPORT_CARDS_SUCCESS = "GET_ALL_REPORT_CARDS_SUCCESS";
export const GET_ALL_REPORT_CARDS_FAILURE = "GET_ALL_REPORT_CARDS_FAILURE";

export const GET_REPORT_CARD_REQUEST = "GET_REPORT_CARD_REQUEST";
export const GET_REPORT_CARD_SUCCESS = "GET_REPORT_CARD_SUCCESS";
export const GET_REPORT_CARD_FAILURE = "GET_REPORT_CARD_FAILURE";

export const ADD_REPORT_CARD_REQUEST = "ADD_REPORT_CARD_REQUEST";
export const ADD_REPORT_CARD_SUCCESS = "ADD_REPORT_CARD_SUCCESS";
export const ADD_REPORT_CARD_FAILURE = "ADD_REPORT_CARD_FAILURE";

export const DELETE_REPORT_CARD_REQUEST = "DELETE_REPORT_CARD_REQUEST";
export const DELETE_REPORT_CARD_SUCCESS = "DELETE_REPORT_CARD_SUCCESS";
export const DELETE_REPORT_CARD_FAILURE = "DELETE_REPORT_CARD_FAILURE";

export const RESET_REPORT_CARD_DELETED = "RESET_REPORT_CARD_DELETED";

export const GET_ALL_SCHOOL_YEARS_REQUEST = "GET_ALL_SCHOOL_YEARS_REQUEST";
export const GET_ALL_SCHOOL_YEARS_SUCCESS = "GET_ALL_SCHOOL_YEARS_SUCCESS";
export const GET_ALL_SCHOOL_YEARS_FAILURE = "GET_ALL_SCHOOL_YEARS_FAILURE";

export const ADD_SCHOOL_YEAR_REQUEST = "ADD_SCHOOL_YEAR_REQUEST";
export const ADD_SCHOOL_YEAR_SUCCESS = "ADD_SCHOOL_YEAR_SUCCESS";
export const ADD_SCHOOL_YEAR_FAILURE = "ADD_SCHOOL_YEAR_FAILURE";

export const DELETE_SCHOOL_YEAR_REQUEST = "DELETE_SCHOOL_YEAR_REQUEST";
export const DELETE_SCHOOL_YEAR_SUCCESS = "DELETE_SCHOOL_YEAR_SUCCESS";
export const DELETE_SCHOOL_YEAR_FAILURE = "DELETE_SCHOOL_YEAR_FAILURE";

export const ADD_TEACHER_REQUEST = "ADD_TEACHER_REQUEST";
export const ADD_TEACHER_SUCCESS = "ADD_TEACHER_SUCCESS";
export const ADD_TEACHER_FAILURE = "ADD_TEACHER_FAILURE";

export const GET_ALL_TEACHERS_REQUEST = "GET_ALL_TEACHERS_REQUEST";
export const GET_ALL_TEACHERS_SUCCESS = "GET_ALL_TEACHERS_SUCCESS";
export const GET_ALL_TEACHERS_FAILURE = "GET_ALL_TEACHERS_FAILURE";

export const GET_TEACHER_REQUEST = "GET_TEACHER_REQUEST";
export const GET_TEACHER_SUCCESS = "GET_TEACHER_SUCCESS";
export const GET_TEACHER_FAILURE = "GET_TEACHER_FAILURE";

export const UPDATE_TEACHER_REQUEST = "UPDATE_TEACHER_REQUEST";
export const UPDATE_TEACHER_SUCCESS = "UPDATE_TEACHER_SUCCESS";
export const UPDATE_TEACHER_FAILURE = "UPDATE_TEACHER_FAILURE";

export const DELETE_TEACHER_REQUEST = "DELETE_TEACHER_REQUEST";
export const DELETE_TEACHER_SUCCESS = "DELETE_TEACHER_SUCCESS";
export const DELETE_TEACHER_FAILURE = "DELETE_TEACHER_FAILURE";

export const ADD_OR_UPDATE_TEACHER_FAILURE_CONFLICT = "ADD_OR_UPDATE_TEACHER_FAILURE_CONFLICT";

export const RESET_TEACHER_LOADED = "RESET_TEACHER_LOADED";
export const RESET_TEACHER_DELETED = "RESET_TEACHER_DELETED";
export const RESET_TEACHER_EXISTS = "RESET_TEACHER_EXISTS";

export const SEND_REPORT_CARD_REQUEST = "SEND_REPORT_CARD_REQUEST";
export const SEND_REPORT_CARD_SUCCESS = "SEND_REPORT_CARD_SUCCESS";
export const SEND_REPORT_CARD_FAILURE = "SEND_REPORT_CARD_FAILURE";

export const SEND_MULTIPLE_REPORT_CARDS_REQUEST = "SEND_MULTIPLE_REPORT_CARDS_REQUEST";
export const SEND_MULTIPLE_REPORT_CARDS_SUCCESS = "SEND_MULTIPLE_REPORT_CARDS_SUCCESS";
export const SEND_MULTIPLE_REPORT_CARDS_FAILURE = "SEND_MULTIPLE_REPORT_CARDS_FAILURE";

export const TOGGLE_MODAL_EDIT_STUDENT = "TOGGLE_MODAL_EDIT_STUDENT";
export const TOGGLE_MODAL_EDIT_PARENT = "TOGGLE_MODAL_EDIT_PARENT";
export const TOGGLE_MODAL_EDIT_TEACHER = "TOGGLE_MODAL_EDIT_TEACHER";
export const TOGGLE_MODAL_CONFIRM_DELETE = "TOGGLE_MODAL_CONFIRM_DELETE";
export const TOGGLE_MODAL_CHOOSE_RECEIVER = "TOGGLE_MODAL_CHOOSE_RECEIVER";
export const TOGGLE_MODAL_CONFIRM_SEND_ALL = "TOGGLE_MODAL_CONFIRM_SEND_ALL";
export const TOGGLE_MODAL_SENDING_NOTICES_PROGRESS = "TOGGLE_MODAL_SENDING_NOTICES_PROGRESS";
export const TOGGLE_MODAL_NOTICE_DETAILS = "TOGGLE_MODAL_NOTICE_DETAILS";
export const TOGGLE_MODAL_DELETE_CLASS_STUDENTS = "TOGGLE_MODAL_DELETE_CLASS_STUDENTS";
export const TOGGLE_MODAL_TRANSFER_STUDENTS = "TOGGLE_MODAL_TRANSFER_STUDENTS";

export const ADD_NOTICE_REQUEST = "ADD_NOTICE_REQUEST";
export const ADD_NOTICE_SUCCESS = "ADD_NOTICE_SUCCESS";
export const ADD_NOTICE_FAILURE = "ADD_NOTICE_FAILURE";

export const GET_ALL_NOTICES_REQUEST = "GET_ALL_NOTICES_REQUEST";
export const GET_ALL_NOTICES_SUCCESS = "GET_ALL_NOTICES_SUCCESS";
export const GET_ALL_NOTICES_FAILURE = "GET_ALL_NOTICES_FAILURE";

export const GET_NOTICE_REQUEST = "GET_NOTICE_REQUEST";
export const GET_NOTICE_SUCCESS = "GET_NOTICE_SUCCESS";
export const GET_NOTICE_FAILURE = "GET_NOTICE_FAILURE";

export const RESET_NOTICE_DETAILS_LOADED = "RESET_NOTICE_DETAILS_LOADED";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const GET_ALL_SCHOOL_CLASSES_REQUEST = "GET_ALL_SCHOOL_CLASSES_REQUEST";
export const GET_ALL_SCHOOL_CLASSES_SUCCESS = "GET_ALL_SCHOOL_CLASSES_SUCCESS";
export const GET_ALL_SCHOOL_CLASSES_FAILURE = "GET_ALL_SCHOOL_CLASSES_FAILURE";

export const ADD_SCHOOL_CLASS_REQUEST = "ADD_SCHOOL_CLASS_REQUEST";
export const ADD_SCHOOL_CLASS_SUCCESS = "ADD_SCHOOL_CLASS_SUCCESS";
export const ADD_SCHOOL_CLASS_FAILURE = "ADD_SCHOOL_CLASS_FAILURE";

export const DELETE_SCHOOL_CLASS_REQUEST = "DELETE_SCHOOL_CLASS_REQUEST";
export const DELETE_SCHOOL_CLASS_SUCCESS = "DELETE_SCHOOL_CLASS_SUCCESS";
export const DELETE_SCHOOL_CLASS_FAILURE = "DELETE_SCHOOL_CLASS_FAILURE";

export const DELETE_CLASS_STUDENTS_REQUEST = "DELETE_CLASS_STUDENTS_REQUEST";
export const DELETE_CLASS_STUDENTS_SUCCESS = "DELETE_CLASS_STUDENTS_SUCCESS";
export const DELETE_CLASS_STUDENTS_FAILURE = "DELETE_CLASS_STUDENTS_FAILURE";

export const TRANSFER_STUDENTS_REQUEST = "TRANSFER_STUDENTS_REQUEST";
export const TRANSFER_STUDENTS_SUCCESS = "TRANSFER_STUDENTS_SUCCESS";
export const TRANSFER_STUDENTS_FAILURE = "TRANSFER_STUDENTS_FAILURE";

export const RESET_SCHOOL_CLASS_DELETED = "RESET_SCHOOL_CLASS_DELETED";

export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

export const REFRESH_SCREEN = "REFRESH_SCREEN";

export const USER_AUTHED = "USER_AUTHED";
