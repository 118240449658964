import * as types from "../types";

const INITIAL_STATE = {
  showAlert: false,
  color: "success",
  title: "",
  content: "",
  icon: "check",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SHOW_ALERT:
      return {
        ...state,
        showAlert: true,
        color: action.payload.color,
        title: action.payload.title,
        content: action.payload.content,
        icon: action.payload.icon,
      };

    case types.HIDE_ALERT:
      return {
        ...state,
        showAlert: false,
      };

    default:
      return state;
  }
};
